// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Clientele_slideclient__bhDMD{
    display: flex;
    width: 100%;
}
.Clientele_slideclient__bhDMD img{
    filter: grayscale(2);
}
.Clientele_slideclient__bhDMD img:hover{
    filter: grayscale(0);
    transition: 0.5s;
}
.Clientele_client_sec__77g\\+v{
    width: -moz-fit-content !important;
    width: fit-content !important;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/common/landingPage/components/Clientele.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,WAAW;AACf;AACA;IACI,oBAAoB;AACxB;AACA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,kCAA6B;IAA7B,6BAA6B;IAC7B,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":["\n.slideclient{\n    display: flex;\n    width: 100%;\n}\n.slideclient img{\n    filter: grayscale(2);\n}\n.slideclient img:hover{\n    filter: grayscale(0);\n    transition: 0.5s;\n}\n.client_sec{\n    width: fit-content !important;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slideclient": `Clientele_slideclient__bhDMD`,
	"client_sec": `Clientele_client_sec__77g+v`
};
export default ___CSS_LOADER_EXPORT___;
