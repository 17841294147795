import React from "react";
import styles from "../../LandingPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { globalModal } from "../../../../../Redux/actions/globalModal";
import { Link } from "react-router-dom";

const CarivaTechnologiesMethod = () => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className={styles.Resources}>
        <h1>Our Top 1% Resources</h1>
        <div
          className={`${styles.LandingPageTopDevelopers} justify-content-center mx-auto col-11`}
        >
          <div className={`${styles.DeveloperCardLandingPage} col-6`}>
            <div className={styles.cards}>
              <div className={styles.cardsUpper}>
                {/* <img src="https://wallpapers.com/images/hd/pretty-profile-pictures-i1rumnm6oi0lry1s.jpg" rounded width={50} height={50} style={{ borderRadius: '10px', marginRight: '10px' }} alt="Md Mudassar" /> */}
                <div className={`${styles.card_details} d-flex`}>
                  <div className={"styles.dev_details"}>
                    <h5>
                      Md Mudassar{" "}
                      <span className={styles.des_clss}>React Developer</span>
                    </h5>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        padding: "0",
                      }}
                    >
                      Experience:{" "}
                      <strong style={{ color: "#000", fontWeight: "500" }}>
                        4 years
                      </strong>
                    </p>
                    {/* <p style={{fontSize:'13px',fontWeight:'300'}}>Availability: <strong style={{color:'#000'}}>Full-Time</strong></p> */}
                  </div>
                  <div className={styles.dev_exp}>
                    <p>Also worked with</p>
                    <div className={styles.experience}>
                      <span className={styles.techs}>Javasript</span>
                      <span className={styles.techs}>HTML & CSS</span>
                      <span className={styles.techs}>Node.js</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dev_about}>
                <p>
                  Md Mudassar is a software developer with 4+ years of
                  experience in building the web and software applications.
                </p>
              </div>
              <div className={styles.hire_details}>
                <div className={styles.dev_exp2}>
                  <p>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#ffcf3d", marginRight: "4px" }}
                    />
                    Expert in
                  </p>
                  <div className={styles.experience}>
                    <span className={styles.techs}>Javasript</span>
                    <span className={styles.techs}>HTML & CSS</span>
                    <span className={styles.techs}>Node.js</span>
                    <span className={styles.techs}>React</span>
                  </div>
                </div>
                <div className="hire-btnn">
                  <button
                    className={styles.hire_btn}
                    onClick={() =>
                      dispatch(
                        globalModal({
                          component: "ContactForm",
                          closeButton: true,
                          size: "lg",
                        })
                      )
                    }
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.DeveloperCardLandingPage} col-6`}>
            <div className={styles.cards}>
              <div className={styles.cardsUpper}>
                {/* <img src="https://wallpapers.com/images/hd/pretty-profile-pictures-i1rumnm6oi0lry1s.jpg" rounded width={50} height={50} style={{ borderRadius: '10px', marginRight: '10px' }} alt="Md Mudassar" /> */}
                <div className={`${styles.card_details} d-flex`}>
                  <div className={"styles.dev_details"}>
                    <h5>
                      Rohit Birla
                      <span className={styles.des_clss}>DevOps Engineer</span>
                    </h5>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        padding: "0",
                      }}
                    >
                      Experience:{" "}
                      <strong style={{ color: "#000", fontWeight: "500" }}>
                        6 years
                      </strong>
                    </p>
                    {/* <p style={{fontSize:'13px',fontWeight:'300'}}>Availability: <strong style={{color:'#000'}}>Full-Time</strong></p> */}
                  </div>
                  <div className={styles.dev_exp}>
                    <p>Also worked with</p>
                    <div className={styles.experience}>
                      <span className={styles.techs}>Java</span>
                      <span className={styles.techs}>Python</span>
                      <span className={styles.techs}>Ruby</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dev_about}>
                <p>
                  A DevOps Engineer having 6+ years of experience in managing
                  the development, testing, and support processes in a software
                  development project life cycle.
                </p>
              </div>
              <div className={styles.hire_details}>
                <div className={styles.dev_exp2}>
                  <p>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#ffcf3d", marginRight: "4px" }}
                    />
                    Expert in
                  </p>
                  <div className={styles.experience}>
                    <span className={styles.techs}>Java</span>
                    <span className={styles.techs}>Javasript</span>
                    <span className={styles.techs}>Python</span>
                    <span className={styles.techs}>Ruby</span>
                    <span className={styles.techs}>AWS</span>
                  </div>
                </div>
                <div className="hire-btnn">
                  <button
                    className={styles.hire_btn}
                    onClick={() =>
                      dispatch(
                        globalModal({
                          component: "ContactForm",
                          closeButton: true,
                          size: "lg",
                        })
                      )
                    }
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.DeveloperCardLandingPage} col-6`}>
            <div className={styles.cards}>
              <div className={styles.cardsUpper}>
                {/* <img src="https://wallpapers.com/images/hd/pretty-profile-pictures-i1rumnm6oi0lry1s.jpg" rounded width={50} height={50} style={{ borderRadius: '10px', marginRight: '10px' }} alt="Md Mudassar" /> */}
                <div className={`${styles.card_details} d-flex`}>
                  <div className={"styles.dev_details"}>
                    <h5>
                      Sayan Banerjee
                      <span className={styles.des_clss}>
                        Servicenow Associate
                      </span>
                    </h5>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        padding: "0",
                      }}
                    >
                      Experience:{" "}
                      <strong style={{ color: "#000", fontWeight: "500" }}>
                        5 years
                      </strong>
                    </p>
                    {/* <p style={{fontSize:'13px',fontWeight:'300'}}>Availability: <strong style={{color:'#000'}}>Full-Time</strong></p> */}
                  </div>
                  <div className={styles.dev_exp}>
                    <p>Also worked with</p>
                    <div className={styles.experience}>
                      <span className={styles.techs}>Javasript</span>
                      <span className={styles.techs}>Java</span>
                      <span className={styles.techs}>ITSM</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dev_about}>
                <p>
                  Proficiency with ServiceNow development, such as scripting,
                  workflows, and integrations. Experience with software
                  development methodologies such as Agile.
                </p>
              </div>
              <div className={styles.hire_details}>
                <div className={styles.dev_exp2}>
                  <p>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#ffcf3d", marginRight: "4px" }}
                    />
                    Expert in
                  </p>
                  <div className={styles.experience}>
                    <span className={styles.techs}>Javasript</span>
                    <span className={styles.techs}>Java</span>
                    <span className={styles.techs}>ITSM</span>
                    <span className={styles.techs}>HRSD</span>
                    <span className={styles.techs}>REST</span>
                  </div>
                </div>
                <div className="hire-btnn">
                  <button
                    className={styles.hire_btn}
                    onClick={() =>
                      dispatch(
                        globalModal({
                          component: "ContactForm",
                          closeButton: true,
                          size: "lg",
                        })
                      )
                    }
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.DeveloperCardLandingPage} col-6`}>
            <div className={styles.cards}>
              <div className={styles.cardsUpper}>
                {/* <img src="https://wallpapers.com/images/hd/pretty-profile-pictures-i1rumnm6oi0lry1s.jpg" rounded width={50} height={50} style={{ borderRadius: '10px', marginRight: '10px' }} alt="Md Mudassar" /> */}
                <div className={`${styles.card_details} d-flex`}>
                  <div className={"styles.dev_details"}>
                    <h5>
                      Rahul Batra
                      <span className={styles.des_clss}>AWS Engineer</span>
                    </h5>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        padding: "0",
                      }}
                    >
                      Experience:{" "}
                      <strong style={{ color: "#000", fontWeight: "500" }}>
                        7+ years
                      </strong>
                    </p>
                    {/* <p style={{fontSize:'13px',fontWeight:'300'}}>Availability: <strong style={{color:'#000'}}>Full-Time</strong></p> */}
                  </div>
                  <div className={styles.dev_exp}>
                    <p>Also worked with</p>
                    <div className={styles.experience}>
                      <span className={styles.techs}>AWS</span>
                      <span className={styles.techs}>DevOps</span>
                      <span className={styles.techs}>Ruby</span>
                      <span className={styles.techs}>Node.js</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dev_about}>
                <p>
                  Manage the configuration of all production systems and do
                  recommend process and architecture improvements. Responsible
                  for AWS cloud infrastructures.
                </p>
              </div>
              <div className={styles.hire_details}>
                <div className={styles.dev_exp2}>
                  <p>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#ffcf3d", marginRight: "4px" }}
                    />
                    Expert in
                  </p>
                  <div className={styles.experience}>
                    <span className={styles.techs}>AWS</span>
                    <span className={styles.techs}>DevOps</span>
                    <span className={styles.techs}>Ruby</span>
                    <span className={styles.techs}>Node.js</span>
                  </div>
                </div>
                <div className="hire-btnn">
                  <button
                    className={styles.hire_btn}
                    onClick={() =>
                      dispatch(
                        globalModal({
                          component: "ContactForm",
                          closeButton: true,
                          size: "lg",
                        })
                      )
                    }
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.DeveloperCardLandingPage} col-6`}>
            <div className={styles.cards}>
              <div className={styles.cardsUpper}>
                {/* <img src="https://wallpapers.com/images/hd/pretty-profile-pictures-i1rumnm6oi0lry1s.jpg" rounded width={50} height={50} style={{ borderRadius: '10px', marginRight: '10px' }} alt="Md Mudassar" /> */}
                <div className={`${styles.card_details} d-flex`}>
                  <div className={"styles.dev_details"}>
                    <h5>
                      Amir Alam
                      <span className={styles.des_clss}>MERN Developer</span>
                    </h5>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        padding: "0",
                      }}
                    >
                      Experience:{" "}
                      <strong style={{ color: "#000", fontWeight: "500" }}>
                        4 years
                      </strong>
                    </p>
                    {/* <p style={{fontSize:'13px',fontWeight:'300'}}>Availability: <strong style={{color:'#000'}}>Full-Time</strong></p> */}
                  </div>
                  <div className={styles.dev_exp}>
                    <p>Also worked with</p>
                    <div className={styles.experience}>
                      <span className={styles.techs}>Javasript</span>
                      <span className={styles.techs}>HTML & CSS</span>
                      <span className={styles.techs}>Node.js</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dev_about}>
                <p>
                  Amir is responsible for developing, and maintaining web apps
                  using MERN Stack. That includes both frontend & backend
                  development, ensuring seamless performance.
                </p>
              </div>
              <div className={styles.hire_details}>
                <div className={styles.dev_exp2}>
                  <p>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#ffcf3d", marginRight: "4px" }}
                    />
                    Expert in
                  </p>
                  <div className={styles.experience}>
                    <span className={styles.techs}>Javasript</span>
                    <span className={styles.techs}>HTML & CSS</span>
                    <span className={styles.techs}>Node.js</span>
                    <span className={styles.techs}>React</span>
                    {/* <span className={styles.techs}>MongoDB</span> */}
                  </div>
                </div>
                <div className="hire-btnn">
                  <button
                    className={styles.hire_btn}
                    onClick={() =>
                      dispatch(
                        globalModal({
                          component: "ContactForm",
                          closeButton: true,
                          size: "lg",
                        })
                      )
                    }
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.DeveloperCardLandingPage} col-6`}>
            <div className={styles.cards}>
              <div className="d-flex">
                {/* <img src="https://wallpapers.com/images/hd/pretty-profile-pictures-i1rumnm6oi0lry1s.jpg" rounded width={50} height={50} style={{ borderRadius: '10px', marginRight: '10px' }} alt="Md Mudassar" /> */}
                <div className={`${styles.card_details} d-flex`}>
                  <div className={"styles.dev_details"}>
                    <h5>
                      Arya Kumar{" "}
                      <span className={styles.des_clss}>Web Developer</span>
                    </h5>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        padding: "0",
                      }}
                    >
                      Experience:{" "}
                      <strong style={{ color: "#000", fontWeight: "500" }}>
                        6 years
                      </strong>
                    </p>
                    {/* <p style={{fontSize:'13px',fontWeight:'300'}}>Availability: <strong style={{color:'#000'}}>Full-Time</strong></p> */}
                  </div>
                  <div className={styles.dev_exp}>
                    <p>Also worked with</p>
                    <div className={styles.experience}>
                      <span className={styles.techs}>Javasript</span>
                      <span className={styles.techs}>HTML & CSS</span>
                      <span className={styles.techs}>Bootstrap</span>
                      {/* <span className={styles.techs}>React</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.dev_about}>
                <p>
                  Arya executes designs & assignments with the use of web
                  applications, scripts, and programming languages such as HTML,
                  CSS, JavaScript, Bootstrap and APIs.
                </p>
              </div>
              <div className={styles.hire_details}>
                <div className={styles.dev_exp2}>
                  <p>
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#ffcf3d", marginRight: "4px" }}
                    />
                    Expert in
                  </p>
                  <div className={styles.experience}>
                    <span className={styles.techs}>Javasript</span>
                    <span className={styles.techs}>HTML & CSS</span>
                    <span className={styles.techs}>Bootstrap</span>
                    <span className={styles.techs}>React</span>
                    {/* <span className={styles.techs}>MongoDB</span> */}
                  </div>
                </div>
                <div className="hire-btnn">
                  <button
                    className={styles.hire_btn}
                    onClick={() =>
                      dispatch(
                        globalModal({
                          component: "ContactForm",
                          closeButton: true,
                          size: "lg",
                        })
                      )
                    }
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hire_btn2 text-center mt-3">
          <Link className={styles.hire_btn2} to="/bench">
            See More Talents
          </Link>
          {/* <button>See More Talents</button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarivaTechnologiesMethod;
