import React, { useEffect } from 'react'
import styles from "../../candidates/Candidates.module.css"
import Table from '../../../../components/common/childA/table/MyTable'
import Pagination from '../../../../components/common/childA/pagination/MyPagination'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SearchBar from "../../../../components/searchBar/SearchBar";
import CustomDropdown from "../../../../components/common/childB/commonDropdown/CustomDropdown";
import { useState, useMemo } from 'react';
import { getLength, getUsers } from '../../../../components/common/childA/usersapi/UserApis';
import { useDispatch, useSelector } from 'react-redux';
import MyPagination from '../../../../components/common/childA/pagination/MyPagination';
import stylesTable from "../../../../components/common/childA/table/MyTable.module.css"
import { BServer } from '../../../../server/backend';
import { appliedMemberInAJobAction } from '../../../../Redux/actions/job/appliedMemberInAJobAction';
import { useParams } from 'react-router-dom';
import { axiosInstanceWithToken } from '../../../../services/axios/axiosInterceptors';
import { toast } from 'react-toastify';


const AppliedUsersInAJob = () => {
  const appliedProfiles = useSelector((state) => state?.appliedMemberInAJobReducer)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const params = useParams()
  const totalItems = appliedProfiles?.length;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const tableData = appliedProfiles?.documents?.slice(firstIndex, lastIndex)
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const inputOnChangeHandle = (e) => {
    setSearchInput(e.target.value)
  }
  console.log('appliedProfiles', appliedProfiles)
  useEffect(() => {
    dispatch(appliedMemberInAJobAction(params?.userRole, params?._id, params?.jobId))
  }, [])

  const TableHead = ["SI No.", "Picture", "Member ID", "Name", "Experience", "Email", "Mobile", "Picked"]
  const TableBody = ["memberId", "profileId.personalInformation.profilePicture", "memberId", "name", "profileId.professionalExperience.professionalExperience_currentDesignation", "experience", "email", "mobileNumber", "profileId.personalInformation.resume", "isActive"]
  const experience = (i) => {
    let valueY
    let valueM
    if (params?.userRole === 'developer') {
      valueY = i?.userId?.profileId?.professionalExperience?.professionalExperience_totalExperienceInYear
      valueM = i?.userId?.profileId?.professionalExperience?.professionalExperience_totalExperienceInMonth
      return `${valueY}${valueY && 'Y'} ${valueM && valueM}${valueM && 'M'}`
    } else if (params?.userRole === 'agency') {
      valueY = i?.userId?.profileId?.overview?.overview_industrialExperience
      return `${valueY}${valueY && 'Y'}`
    }

  }

  const markShortlist = async (appliedUserId, appliedMemberId, appliedUserRole) => {
    await axiosInstanceWithToken.post(`${BServer}/admin/job/handle-status-job/${params?._id}/${params?.jobId}/${appliedUserId}/${appliedMemberId}/${appliedUserRole}`)
      .then((res) => {
        console.log('response markShortlist 45', res)
        toast.success(res?.data?.message)
        if (res?.status == 200) {
          dispatch(appliedMemberInAJobAction(params?.userRole, params?._id, params?.jobId))
        }
      })
  }

  return (
    <div>

      <section className={styles.banner}>
        <h3 style={{ textTransform: 'capitalize' }}>{params?.userRole}</h3>
        <h4>
          <span>Applied: {appliedProfiles?.count0}</span>
          <span>Shortlisted: {appliedProfiles?.count1}</span>
        </h4>
        {/* <div className={styles.searchbar}>
            <input type="text" placeholder="Enter name or member id" name='searchInput' value={searchInput} onChange={inputOnChangeHandle} />

            <button>Search</button>

          </div> */}
      </section>




      <div className={stylesTable.container}>
        <table responsive="sm" className={stylesTable.custom_table}>
          <thead>
            <tr>
              {TableHead.map(val => {
                return (
                  <th key={val}>{val}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((i, idx) => {
              return (
                // <MyTableRow key={user?.memberId} userData={user} tableBody={tableBody} click={props.click} clickFunc={props.clickFunc} />
                <tr className={!i?.userId?.profileId?._id && `bg-danger`} style={{ cursor: !i?.userId?.profileId?._id && 'not-allowed' }}>
                  <td><span>{idx + 1}</span></td>
                  <td><span>
                    <img
                      src={`${BServer}/uploads/${i?.userId?.profileId.personalInformation?.profilePicture}`}
                      alt=""
                      style={{ width: 40, height: 40, cursor: !i?.userId?.profileId?._id ? 'not-allowed' : 'pointer' }}
                      onClick={() => i?.userId?.profileId?._id && window.open(`/admin/profile-${i?.userRole}/${encodeURIComponent(JSON.stringify(i?.userId?._id))}?category=candidate`, '_blank')}
                    />
                  </span></td>
                  <td><span>{i?.memberId}</span></td>
                  <td><span>{i?.userId?.name}</span></td>
                  <td><span>{experience(i)}</span></td>

                  <td><span>{i?.userId?.email}</span></td>
                  <td>

                    <span>{i['userId.mobile'] ? i['userId.mobile'] : 'No data'}</span>
                  </td>


                  <td>
                    <span style={{
                      cursor: i?.userId?.profileId?.shortlistedJob?.job?.some(obj => obj._id == params?._id) ? 'not-allowed' : 'pointer',
                      color: i?.userId?.profileId?.shortlistedJob?.job?.some(obj => obj._id == params?._id) ? 'green' : 'red'
                    }}
                      onClick={() => markShortlist(
                        i?.userId?._id,
                        i?.userId?.memberId,
                        i?.userId?.userRole
                      )}
                    >
                      {/* <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label=""
                          checked={i?.userId?.profileId?.shortlistedJob?.job?.some(obj => obj._id == params?._id)}
                          onClick={() => markShortlist(
                            i?.userId?._id,
                            i?.userId?.memberId,
                            i?.userId?.userRole
                          )}
                        // disabled={}
                        />
                      </Form> */}

                      {i?.userId?.profileId?.shortlistedJob?.job?.some(obj => obj._id == params?._id) ?
                        'Shortlisted'
                        :
                        'Not-shortlisted'


                      }
                    </span>
                  </td>

                </tr>
              )
            })}
          </tbody>
        </table>

      </div>

      <div className='a d-flex justify-content-center'>

        <MyPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

      </div>

    </div>
  )
}

export default AppliedUsersInAJob;