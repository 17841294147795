import React from "react";
import styles from '../../../partners/Partners.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import tilogo from '../../../../../assets/images/footer-logo.png'

import { Link, useNavigate } from "react-router-dom";
const LandingFooter = () => {
  const navigate=useNavigate()
  return (
    <footer className={styles.ftralinks}>
      <div className={styles.footer_map}>
        <div className={styles.map_sec_1}>
          <h3> 2000+ Developers</h3>
          <h6>have already joined Taln Finium</h6>
        </div>
        <div className={styles.footerPlusdigit}>
          <div className={` text-white mt-4 ${styles.ftr_titles}`}>
            <h4>15+</h4>
            <h5>Job Types</h5>
          </div>
          <div className={` text-white mt-4 ${styles.ftr_titles}`}>
            <h4>160+</h4>
            <h5>Technologies</h5>
          </div>
          <div className={` text-white mt-4 ${styles.ftr_titles}`}>
            <h4>100+</h4>
            <h5>Tie-up Partners</h5>
          </div>
        </div>
        <div className={`${styles.patnerbuttonMain} d-flex gap-3`}>
          <div className={styles.patnerbuttonChild}>
            {/* <Link to='hire-developer' className={styles.footer_btn_1}>Hire Developers</Link> */}
            <button className={styles.footer_btn_1} onClick={()=>navigate('/hire-developer')}>Hire Telents</button>
          </div>
          <div className={styles.patnerbuttonChild}>
            {/* <button className={styles.footer_btn_2}>Apply for Jobs</button> */}
            <Link to='apply-job' className={styles.footer_btn_2} >Find Jobs</Link>
          </div>
        </div>
      </div>

      <div className="bg-black text-white bottom-footer">
        <div className={` d-flex ${styles.linksec}`}>
          <div
            className={styles.linksecChild}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h4>Quick Links</h4>
            {/* <a href="">About us</a> */}
            <Link  to='/partner' >Partners</Link>
            <Link  to='/hire-developer' >Hire Talents</Link>
            <Link  to='/signup' >Becoma a Partner</Link>
          </div>
          <div
            className={styles.linksecChild}
            style={{ display: "flex", flexDirection: "column"  }}
          >
            <h4>Useful Links</h4>
            <Link  to='/signin' >Login</Link>
            <Link  to='/signup' >Sign Up As Developer</Link>
            <Link  to='/signup' >Sign Up As Agency</Link>
          </div>
          <div
            className={styles.linksecChild}
            style={{ display: "flex", flexDirection: "column"  }}
          >
            <h4>Important Links</h4>
            <Link  to='/' >Privacy Policy</Link>
            <Link  to='/' >Terms & Conditions</Link>
            {/* <Link  to='/signup' >Sign Up As Agency</Link> */}
          </div>



        </div>

        <div
          className={`${styles.maplinksec} `}
          id="footer-elem"
        >
          <div className={styles.maplinksecChild}>
            <div className={ ` d-flex flex-column justify-content-center ${styles.linkftr} ` }>
              <div
                className="text-dark justify-content-center fw-regular text-white"
                id={styles.location1}
              >
                United States
              </div>
              <div className={styles.address_text}>
                <div className={`pt-3 d-flex ${styles.item1}`}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ color: "#f00000", fontSize: "18px" }}
                  />
                  <div className={styles.add_elem1}>
                    2512 East Evergreen Blvd, <br />
                    1276 Vancouver, WA 98661
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.maplinksecChild}>
            <div className={ `d-flex flex-column justify-content-center ${styles.linkftr1} ` }>
              <div
                className="text-dark justify-content-center fw-regular text-white"
                id={styles.location1}
              >
                India
              </div>
              <div className={styles.address_text}>
                <div className={`pt-3 d-flex ${styles.item1}`}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ color: "#f00000", fontSize: "18px" }}
                  />
                  <div className={styles.add_elem1}>
                    32, Chowringhee Road, Om Tower, 7th Floor <br />
                    Unit No- 706 Park Street Kolkata 700071
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.maplinksecChild}>
            <div className={`${styles.marleft} d-flex flex-column justify-content-center`} >
              <div
                className="text-dark justify-content-center fw-regular text-white"
                id={styles.location2}
              >
                India
              </div>
              <div className={styles.address_text}>
                <div className={`pt-3 d-flex ${styles.item1}`}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ color: "#f00000", fontSize: "18px" }}
                  />
                  <div className={styles.add_elem1}>
                    225, MN Saha Rd, Nearby Kids N Kids School
                    <br />
                    Pathak Bari, Asansol, West Bengal 713301
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className={`mt-3 mb-3 d-flex ${styles.linksec}  `}>
          <div className={`${styles.linksecChild}`}>
            <div className="mx-auto mt-4 mx-auto ">
              <img className="footer-img-logo" src={tilogo} width={190} />
            </div>
            <p
              style={{
                fontSize: "12px",
                textAlign: "left",
                paddingTop: "5px",
                fontWeight: "200",
                marginTop: "5px"
              }}
            >
              The One Stop Solution For All Talent Marketplace . Let Taln Finium brings the best hiring dedicated developers.
            </p>
          </div>
          <div className={`${styles.linksecChild}`}>
            <div className="d-flex flex-column">
              <div className="text-start font-weight-bold">
                <p className="connect-withus text-start mt-4 fw-regular ">
                  {" "}
                  Connect with Us
                </p>
              </div>
              <div className="text-connect ">
                <div className="item1-connect">
                  <p
                    className="text-white text-start"
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      paddingTop: "5px",
                      fontWeight: "200",
                    }}
                  >
                    {" "}
                    +91 75480 90001
                    <br />
                  </p>
                  <p
                    className="text-white text-start "
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      paddingTop: "0px",
                      fontWeight: "200",
                    }}
                  >
                    contact@talnfinium.cloud{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`mb-3 p-0 ${styles.linksecChild}`}>
            <div className="d-flex flex-column justify-content-center ">
              <div className="justify-content-center font-weight-bold">
                <p className="connect-withus fw-regular text-start mt-4">
                  Career
                </p>
              </div>
              <div className="text-connect">
                <div className="item1-connect">
                  <p
                    className="text-white text-start "
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      paddingTop: "5px",
                      fontWeight: "200",
                    }}
                  >
                    +91 75480 90002
                    <br />
                  </p>
                  <p
                    className="text-white text-start "
                    style={{
                      marginBottom: "0px",
                      fontSize: "12px",
                      paddingTop: "0px",
                      fontWeight: "200",
                    }}
                  >
                    jobs@talnfinium.cloud{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          style={{
            marginBottom: "-50px",
            padding: "20px",
            textAlign: "center",
            fontSize: "8px",
            fontWeight: "200",
          }}
        >
          Copyright © 2013-2024 | All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default LandingFooter;
