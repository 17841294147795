import React, { useEffect } from 'react'
import styles from "./Candidates.module.css"
import Table from '../../../components/common/childA/table/MyTable'
import Pagination from 'react-bootstrap/Pagination';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import CustomDropdown from "../../../../src/components/common/childB/commonDropdown/CustomDropdown";
import { useState, useMemo } from 'react';
import { getLength, getUsers, fakedata } from '../../../components/common/childA/usersapi/UserApis';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMemberOfSingleRoleAction } from '../../../Redux/actions/member/getAllMemberOfSingleRoleAction';
import MyPagination from '../../../components/common/childA/pagination/MyPagination';
import stylesTable from "../../../components/common/childA/table/MyTable.module.css"
import { BServer } from '../../../server/backend';
import { toggleStatusAgencyAndDevAction } from '../../../Redux/actions/member/toggleStatusAgencyAndDevAction';
import noprofileFound from "../../../assets/images/no-result-found.png"

const Candidates = () => {
  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const allMember = useSelector((state) => state?.getAllMemberOfSingleRoleReducer)
  const inputOnChangeHandle = (e) => {
    setSearchInput(e.target.value)
  }
  console.log('searchInput', searchInput)
  useEffect(() => {
    dispatch(getAllMemberOfSingleRoleAction('developer', searchInput, 1, 10))
  }, [searchInput])

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const totalItems = allMember?.length;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const tableData = allMember?.documents?.slice(firstIndex, lastIndex)
  console.log('tableData', tableData)
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const TableHead = ["SI No.", "Picture", "Member ID", "Name", "Desgination", "Experience", "Email", "Mobile", "Resume", "Status"]

  console.log("candidates.jsx line : 39", tableData.length)

  return (
    <div>
      <section className="banner">
        <section className={styles.banner}>
          <h3>Candidates</h3>
          <div className={styles.searchbar}>
            <input type="text" placeholder="Enter name or member id" name='searchInput' value={searchInput} onChange={inputOnChangeHandle} />

            <button>Search</button>

          </div>
        </section>
      </section>

      {/* <Table tableHead={''} data={tableData} /> */}
      {/* <Pagination totalPage={Math.ceil(totalItems / itemsPerPage)} page={page} limit={limit} siblings={1} onPageChange={handlePageChange} /> */}



      <div className={stylesTable.container}>
        {/* here  */}
        {tableData.length > 0 ?  (<table responsive="sm" className={`${stylesTable.custom_table} mt-5 mb-5`}>
          <thead>
            <tr>
              {TableHead.map(val => {
                return (
                  <th key={val}>{val}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((i, idx) => {
              return (
                // <MyTableRow key={user?.memberId} userData={user} tableBody={tableBody} click={props.click} clickFunc={props.clickFunc} />
                <tr style={{
                  background: !i['profileId._id'] && 'red'
                }}>
                  <td><span>{idx + 1}</span></td>
                  <td><span>
                    <img
                      src={`${BServer}/uploads/${i['profileId.personalInformation.profilePicture']}`} alt=""
                      style={{ width: 40, height: 40, cursor: !i['profileId._id'] ? 'not-allowed' : 'pointer' }}

                      onClick={() => i['profileId._id'] && window.open(`/admin/profile-${i?.userRole}/${encodeURIComponent(JSON.stringify(i?._id))}?category=candidate`, '_blank')}
                    />
                  </span></td>
                  <td><span>{i?.memberId}</span></td>
                  <td><span>{i?.name}</span></td>
                  <td><span>{i['profileId.professionalExperience.professionalExperience_currentDesignation']}</span></td>
                  <td><span>{i['profileId.professionalExperience.professionalExperience_totalExperienceInMonth']}</span></td>
                  <td><span>{i['email']}</span></td>
                  <td><span>{i['mobile'] ? i['mobile'] : 'xxxxx'}</span></td>
                  <td><span >
                    {
                      i['profileId.personalInformation.resume'] ?
                        <a href={`${BServer}/uploads/${i['profileId.personalInformation.resume']}`} target='_blank'>view</a>
                        :
                        <span>No data</span>
                    }


                  </span></td>
                  <td>
                    <span
                      style={{ cursor: "pointer", color: i?.isActive ? 'green' : 'red' }}
                      onClick={() => dispatch(toggleStatusAgencyAndDevAction(i?._id, i?.memberId, i?.userRole, searchInput, currentPage, itemsPerPage))}
                    >


                      {/* <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={i?.isActive}
                        onClick={() => dispatch(toggleStatusAgencyAndDevAction(i?._id, i?.memberId, i?.userRole, searchInput, currentPage, itemsPerPage))}

                      />

                    </Form> */}
                      {i?.isActive ? "Active" : "Inactive"}
                    </span>
                  </td>

                </tr>
              )
            })}
          </tbody>
        </table>) : <div className={styles.noProfilFound}>
              <img className={styles.noprofileFoundImage} src={noprofileFound} alt="" />
              <p >No Data Found</p>
            </div>}

      </div>

      {tableData.length > 0 && (< div className='a d-flex justify-content-center mt-5 mb-5' >

        <MyPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

      </div>)}

    </div >

  )
}

export default Candidates

{/* <Pagination>
        <Pagination.First onClick={() => setCurrentPage(1)} />
        <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
        {paginationItems}
        <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
      </Pagination> */}