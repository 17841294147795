import React, { useCallback, useEffect, useMemo } from 'react'
import * as Yup from 'yup';

import styles from './CreateProfileDev.module.css'
import { useFormik } from "formik"
import ProfilePageInputfeild from './ProfilePageInputfeild'
import ProfilePageDropdownFeild from './ProfilePageDropdownFeild'
import { initialValueDev, nameValueDev } from '../../../../assets/initialValues/createProfile'
import { useDispatch, useSelector } from 'react-redux'
import CascadingDropdown from './CascadingDropdown'
import { devProfileValidation } from '../../../../assets/yupValidation/createProfile/devProfileValidation'
import { createProfileDevAction } from '../../../../Redux/actions/profile/createProfileDevAction'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { locationAction } from '../../../../Redux/actions/locationAction'
import LocationDropdown from './LocationDropdown'
import { viewProfileDevAction } from '../../../../Redux/actions/profile/viewProfile/viewProfileDevAction'
import { fileUpload } from '../../../../Redux/actions/fileUpload/fileUpload'
import { BServer } from '../../../../server/backend'
import { viewAgencySingleDeveloperAction } from '../../../../Redux/actions/profile/viewProfile/viewAgencySingleDeveloperAction'
import { viewInHouseSingleDeveloperAction } from '../../../../Redux/actions/profile/viewProfile/viewInHouseSingleDeveloperAction'
import AgencyPageFileInputFeild from '../../../forAgency/profilePage/createProfile/AgencyPageFileInputFeild'


const CreateProfileDev = () => {
    const authentication = useSelector((state) => state.authentication)
    const location = useSelector((state) => state?.location)
    const userIdFromUseLocation = useLocation()
    const profileDetails = useSelector((state) => authentication?.userRole == 'developer' ? state?.myProfile?.profileData : state?.viewProfileDev?.profileData)
    const fileUploaded = useSelector((state) => state?.fileUpload)
    const formDropdown = useSelector((state) => state?.formDropdown)

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    let validation
    if (authentication?.userRole !== 'developer') {
        validation = Yup.object().shape({
            ...devProfileValidation,
            mobile: Yup.string()
                .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                .required('Mobile number is required'),
        }
        )

    } else {
        validation = Yup.object().shape({
            ...devProfileValidation,

        }
        )
    }

    const editOne = {
        email: authentication.userEmail,
        fullName: profileDetails?.personalInformation?.fullName,
        dateOfBirth: profileDetails?.personalInformation?.dateOfBirth,
        gender: profileDetails?.personalInformation?.gender,
        profilePicture: profileDetails?.personalInformation?.profilePicture,
        resume: profileDetails?.personalInformation?.resume,
        mobile: profileDetails?.personalInformation?.mobile,
        currentLocation_country: profileDetails?.currentLocation?.currentLocation_country,
        currentLocation_state: profileDetails?.currentLocation?.currentLocation_state,
        currentLocation_district: profileDetails?.currentLocation?.currentLocation_district,
        currentLocation_city: profileDetails?.currentLocation?.currentLocation_city,
        currentLocation_pinCode: profileDetails?.currentLocation?.currentLocation_pinCode,

        preferredLocation: profileDetails?.preferredLocation,

        education_instituteName: profileDetails?.education?.education_instituteName,
        education_specializaion: profileDetails?.education?.education_specializaion,
        education_qualification: profileDetails?.education?.education_qualification,
        education_yearOfPassing: profileDetails?.education?.education_yearOfPassing,

        professionalExperience_currentDesignation: profileDetails?.professionalExperience?.professionalExperience_currentDesignation, professionalExperience_currentOrganization: profileDetails?.professionalExperience?.professionalExperience_currentOrganization, professionalExperience_totalExperienceInYear: profileDetails?.professionalExperience?.professionalExperience_totalExperienceInYear, professionalExperience_totalExperienceInMonth: profileDetails?.professionalExperience?.professionalExperience_totalExperienceInMonth,
        professionalExperience_noticePeriodInMonth: profileDetails?.professionalExperience?.professionalExperience_noticePeriodInMonth,
        professionalExperience_skills: profileDetails?.professionalExperience?.professionalExperience_skills,
        professionalExperience_projectCompleted: profileDetails?.professionalExperience?.professionalExperience_projectCompleted,
        professionalExperience_currentPayout: profileDetails?.professionalExperience?.professionalExperience_currentPayout,
        professionalExperience_expectedPayout: profileDetails?.professionalExperience?.professionalExperience_expectedPayout,

        social_linkedin: profileDetails?.social?.social_linkedin,
        social_git: profileDetails?.social?.social_git
    }

    const { values, handleChange, handleSubmit, resetForm, errors, setFieldValue, handleBlur, touched } = useFormik({
        initialValues: params?.edit ? editOne : { ...initialValueDev, email: authentication?.userRole === 'developer' ? authentication.userEmail : '', mobile: authentication?.userRole !== 'developer' && '' }
        ,
        validationSchema: validation,
        enableReinitialize: true,

        // userIdFromUseLocation will only be provided with dispatch when agency try to edit there developer
        onSubmit: (values) => {
            dispatch(createProfileDevAction(values, params?.edit === 'edit' ? 'EDIT' : 'CREATE', (prop) => navigate(prop), userIdFromUseLocation?.state?.userId))

        }
    })
    useEffect(() => {
        if (params?.edit === 'edit' && authentication?.userRole == 'agency') {
            dispatch(viewAgencySingleDeveloperAction(userIdFromUseLocation?.state?.userId))
        } else if (params?.edit === 'edit' && (authentication?.userRole == 'admin' || authentication?.userRole == 'sAdmin')) {
            dispatch(viewInHouseSingleDeveloperAction(userIdFromUseLocation?.state?.userId))
        }
    }, [])


    useEffect(() => {
        dispatch(locationAction('COUNTRY'))
    }, [values.currentLocation_country])
    useEffect(() => {
        if (values.currentLocation_country) {
            dispatch(locationAction('STATE', values.currentLocation_country?.geonameId))
        }
    }, [values.currentLocation_country])

    useEffect(() => {
        if (values.currentLocation_country) {
            dispatch(locationAction('DISTRICT', values.currentLocation_state?.geonameId))
        }
    }, [values.currentLocation_state])
    useEffect(() => {
        if (values.currentLocation_country) {
            dispatch(locationAction('CITY', values.currentLocation_district?.geonameId))
        }
    }, [values.currentLocation_district])
    console.log('eerrr', errors)
    console.log('values', values)
    console.log('profiledata', profileDetails)

    return (
        <>
            <div className={styles.porfile_page}>
                <div className={styles.porfile_page_profile_section}>
                    <img
                        src={
                            values.profilePicture ?
                                `${BServer}/uploads/${fileUploaded?.profilePicture ? fileUploaded?.profilePicture?.file : values?.profilePicture}`
                                :
                                `https://cdn-icons-png.flaticon.com/512/6596/6596121.png`
                        }
                        alt="ProfilePhoto" />
                    <span className={styles.ProfilePageInputfield_error_msg}>{touched?.profilePicture && errors?.profilePicture}</span>
                    <div className={styles.ProfilePagefileinput}>
                        <label htmlFor="profilephoto">
                            Profile Photo
                            <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input type="file" accept="image/*" id="profilephoto" style={{ display: "none" }} name={nameValueDev?.profilePicture} onChange={async (e) => {
                            dispatch(fileUpload(e, setFieldValue))

                        }} />
                    </div>

                    <h5>{values.fullName}</h5>


                </div>

                <div className={styles.porfile_page_personal_section}>

                    <h3>Personal Details</h3>

                    <ProfilePageInputfeild label="Full Name" required={true} type="text" name={nameValueDev.fullName} value={values.fullName} onChange={handleChange} onBlur={handleBlur} error={touched.fullName && errors.fullName} />

                    <ProfilePageInputfeild label="DOB" required={true} type="date" name={nameValueDev.dateOfBirth} value={values?.dateOfBirth ? new Date(values?.dateOfBirth)?.toISOString()?.split('T')[0] : values?.dateOfBirth} onChange={handleChange} onBlur={handleBlur} error={touched.dateOfBirth && errors.dateOfBirth} />



                    <LocationDropdown label="Gender" values={[{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]} required={true} name={nameValueDev.gender} value={values.gender
                    } setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.gender && errors.gender} previousValue={profileDetails?.personalInformation?.gender} />

                    <ProfilePageInputfeild label="Email" required={true} type="email" name={nameValueDev.email} value={values.email} disabled={params?.edit || authentication?.userRole == 'developer'} onChange={handleChange} onBlur={handleBlur} error={touched.email && errors.email} />

                    {
                        (authentication?.userRole === 'agency' || authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin') &&
                        <ProfilePageInputfeild label="Mobile" required={true} type="number" name={nameValueDev.mobile} value={values.mobile} disabled={params?.edit} onChange={handleChange} onBlur={handleBlur} error={touched.mobile && errors.mobile} />
                    }




                    <LocationDropdown label="Current Location Country" values={location?.countries} required={true} name={nameValueDev.currentLocation_country} value={values.currentLocation_country} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_country && errors.currentLocation_country} previousValue={profileDetails?.currentLocation?.currentLocation_country} />

                    <LocationDropdown label="Current Location State" values={location?.states} required={true} name={nameValueDev.currentLocation_state} value={values.currentLocation_state} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_state && errors.currentLocation_state} previousValue={profileDetails?.currentLocation?.currentLocation_state} />


                    <LocationDropdown label="Current Location District" values={location?.districts} required={true} name={nameValueDev.currentLocation_district} value={values.currentLocation_district} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_district && errors.currentLocation_district} previousValue={profileDetails?.currentLocation?.currentLocation_district} />


                    <LocationDropdown label="Current Location City" values={location?.cities} required={true} name={nameValueDev.currentLocation_city} value={values.currentLocation_city} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.currentLocation_city && errors.currentLocation_city} previousValue={profileDetails?.currentLocation?.currentLocation_city} />

                    <ProfilePageInputfeild label="Current Location Pin code" required={true} type="text" name={nameValueDev.currentLocation_pinCode} value={values.currentLocation_pinCode} onChange={handleChange} onBlur={handleBlur} error={touched.currentLocation_pinCode && errors.currentLocation_pinCode} />



                    <CascadingDropdown label={'Preffered Location'} options={formDropdown?.preferredLocation} setFieldValue={setFieldValue} name={nameValueDev.preferredLocation} onBlur={handleBlur} error={touched.preferredLocation && errors.preferredLocation} value={values?.preferredLocation} xyz={'riz'} required={true} />




                    <div className={styles.ProfilePagefileinput}>
                        {/* <label htmlFor="" className='mt-3'>Resume:</label> <br />
                        <input type="file" accept="application/pdf" name={nameValueDev.resume} onChange={(e) => {
                            dispatch(fileUpload(e, setFieldValue))

                        }} />
                        <br />
                        <span className={styles.ProfilePageInputfield_error_msg}>{touched?.resume && errors?.resume}</span> */}

                        <AgencyPageFileInputFeild label="Resume" accept="application/pdf" name={nameValueDev.resume} value={values.resume} onChange={async (e) => {
                            dispatch(fileUpload(e, setFieldValue))

                        }} />
                        {/* <AgencyPageFileInputFeild label="Pan Card" accept="application/pdf"  value={values.documents_panCard} onChange={async (e) => {
                            dispatch(fileUpload(e, setFieldValue))

                        }} /> */}
                        <span className=''>{touched?.resume && errors?.resume}</span>
                    </div>

                    <h3 className='mt-4'>Education</h3>

                    <ProfilePageInputfeild label="University/College Name" required={true} type="text" name={nameValueDev.education_instituteName} value={values.education_instituteName} onChange={handleChange} onBlur={handleBlur} error={touched.education_instituteName && errors.education_instituteName} />

                    <LocationDropdown label="Qualification" values={formDropdown?.qualification} required={true} name={nameValueDev.education_qualification} value={values.education_qualification} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.education_qualification && errors.education_qualification} previousValue={profileDetails?.education?.education_qualification} />

                    <ProfilePageInputfeild label="Specialization" type="text" name={nameValueDev.education_specializaion} value={values.education_specializaion} onChange={handleChange} onBlur={handleBlur} error={touched.education_specializaion && errors.education_specializaion} />


                    <LocationDropdown label="Year Of Passing" values={Array.from({ length: new Date()?.getFullYear() - 1989 }, (_, index) => { return { name: `${1990 + index}` } }
                    )} required={true} name={nameValueDev.education_yearOfPassing} value={values.education_yearOfPassing} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.education_yearOfPassing && errors.education_yearOfPassing} previousValue={profileDetails?.education?.education_yearOfPassing} />

                </div>

                <div className={styles.porfile_page_experience_section}>

                    <h3>Professional Experience</h3>

                    <ProfilePageInputfeild label="Current Designation" type="text" name={nameValueDev.professionalExperience_currentDesignation} value={values.professionalExperience_currentDesignation} onChange={handleChange} />

                    <ProfilePageInputfeild label="Current Organisation Name" type="text" name={nameValueDev.professionalExperience_currentOrganization} value={values.professionalExperience_currentOrganization} onChange={handleChange} />

                    <ProfilePageInputfeild label="Total Experience in Year" type="number" name={nameValueDev.professionalExperience_totalExperienceInYear} value={values.professionalExperience_totalExperienceInYear} onChange={handleChange} min={'0'} max={'30'} onBlur={handleBlur} error={touched.professionalExperience_totalExperienceInYear && errors.professionalExperience_totalExperienceInYear} />

                    <ProfilePageInputfeild label="Total Experience in Month" type="number" name={nameValueDev.professionalExperience_totalExperienceInMonth} value={values.professionalExperience_totalExperienceInMonth} onChange={handleChange} min={'0'} max={'11'} onBlur={handleBlur} error={touched.professionalExperience_totalExperienceInMonth && errors.professionalExperience_totalExperienceInMonth} />

                    <LocationDropdown label="Notice Period" values={[{ name: '0 - 15 days' }, { name: '1 month' }, { name: '2 months' }, { name: '3 months' }]} name={nameValueDev.professionalExperience_noticePeriodInMonth} value={values.professionalExperience_noticePeriodInMonth} setFieldValue={setFieldValue} onBlur={handleBlur} error={touched.professionalExperience_noticePeriodInMonth && errors.professionalExperience_noticePeriodInMonth} previousValue={profileDetails?.professionalExperience?.professionalExperience_noticePeriodInMonth} />


                    <CascadingDropdown label={'Skills'} options={formDropdown?.techStacks} setFieldValue={setFieldValue} name={nameValueDev.professionalExperience_skills} className='mt-5' onBlur={handleBlur} error={touched.professionalExperience_skills && errors.professionalExperience_skills} required={true} edit={params?.edit} value={values?.professionalExperience_skills} />

                    <ProfilePageInputfeild label="No of Projects Completed" type="number" name={nameValueDev.professionalExperience_projectCompleted} value={values.professionalExperience_projectCompleted} onChange={handleChange} min={'0'} max={'30'} />

                    <ProfilePageInputfeild label="Current Payout in Month" type="number" name={nameValueDev.professionalExperience_currentPayout} value={values.professionalExperience_currentPayout} onChange={handleChange} min={'0'} />

                    <ProfilePageInputfeild label="Expected Payout in Month" required={true} type="number" name={nameValueDev.professionalExperience_expectedPayout} value={values.professionalExperience_expectedPayout} onChange={handleChange} onBlur={handleBlur} error={touched.professionalExperience_expectedPayout && errors.professionalExperience_expectedPayout} min={'0'} />

                    <h3 className='mt-4'>Social links</h3>

                    <ProfilePageInputfeild label="Linkedin" type="url" required={true} name={nameValueDev.social_linkedin} value={values.social_linkedin} onChange={handleChange} onBlur={handleBlur} error={touched.social_linkedin && errors.social_linkedin} />

                    <ProfilePageInputfeild label="GitHub" type="url" name={nameValueDev.social_git} value={values.social_git} onChange={handleChange} />

                    <button type='submit' onClick={handleSubmit}>Save profile</button>



                </div>
            </div>
        </>


    )
}

export default CreateProfileDev