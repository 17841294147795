import React from 'react'
import { useDispatch } from 'react-redux'
import { IoMdClose } from "react-icons/io";
import { viewProfileAgencyAction } from '../../../../../Redux/actions/profile/viewProfile/viewProfileAgencyAction';
import { useNavigate } from 'react-router-dom';

const AgencyProfileConfirmation = () => {
    const dispatch = useDispatch()
    // const navigate=useNavigate()
    const handleClose = () => {
        dispatch({ type: "HIDE_MODAL" })
        setTimeout(() => {
            dispatch({ type: 'PROFILE_CREATED' })
            dispatch(viewProfileAgencyAction());
            // navigate('/agency');
            // window.location.href = '/agency';
        }, 600);
    }

    return (
        <div style={{ padding: "20px", position: "relative" }}>
            <span className="closeIcon" style={{
                position: "absolute",
                top: "0px",
                cursor: "pointer",
                right: "6px",
            }} onClick={() => handleClose()} ><IoMdClose size={"24px"} /></span>

            <h3
                style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    fontSize: "21px"
                }}

            >
                Verified
                <span
                    style={{
                        borderRadius: "50%",
                        width: "24px",
                        height: "24px",
                        aspectRatio: 1 / 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ><img
                        src="https://imgs.search.brave.com/lmKgaMx5H-bJwj8c89sZn-NhKvv0xWGEFGr7MZNIjEQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy8y/LzI4L05vdW5fUHJv/amVjdF90aWNrX2lj/b25fNjc1Nzc2X2Nj/LnN2Zw.svg"
                        style={{ width: "22px", height: "22px" }}
                        alt=""
                    /></span>
            </h3>
            <p
                style=
                {{
                    fontWeight: 200,
                    paddingTop: "0px",
                    textAlign: "center",
                    fontSize: "14px",
                }}

            >
                Your profile has been created. Pending for varification
            </p>
        </div>

    )
}

export default AgencyProfileConfirmation