import { useField } from 'formik'
import React, { memo } from 'react'
import styles from './CreateProfileDev.module.css'
const ProfilePageDropdownFeild = ({ label, values, required, ...props }) => {

    // const [field, meta] = useField(props)

    // replace error with the object that conatins error 
    const error = false;

    return (

        <div className={styles.ProfilePageDropdownFeild}>
            <label htmlFor="">{label}{required && <span> *</span>}</label>
            <select  {...props} className={error && styles.errorRedBorder} >
                {values.map(opt => {
                    return (
                        <option value={opt} key={opt}>{opt}</option>
                    )
                })}
            </select>
            {/* {meta.touched && meta.error ? (
                <div className={styles.ProfilePageInputfield_error_msg}>{meta.error}</div>
            ) : null} */}
        </div>

    )
}

export default memo(ProfilePageDropdownFeild)