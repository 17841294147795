import styles from "../MyDeveloper.module.css"
import { FaGithub, FaLinkedin, FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstanceWithToken } from "../../../../../services/axios/axiosInterceptors";
import { BServer } from "../../../../../server/backend";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { viewAgencyAllDeveloperAction } from "../../../../../Redux/actions/profile/viewProfile/viewAgencyAllDeveloperAction";
import { viewInHouseAllDeveloperAction } from "../../../../../Redux/actions/profile/viewProfile/viewInHouseAllDeveloperAction";

const MyDeveloperCard = props => {
    const authentication = useSelector((state) => state?.authentication)
    const { name, desgination, address, skill, github, linkedin, experience, email, imgsrc, userId } = props
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const handleNavigate = () => {
        //         navigate(
        //             params?.agencyId?
        //                 `/admin/profile-developer/${encodeURIComponent(JSON.stringify(userId))
        // }`
        //                 :
        //                 `view / ${ encodeURIComponent(JSON.stringify(userId)) } `)

        // if (authentication?.userRole==='agency'){
        //     navigate(`view / ${ encodeURIComponent(JSON.stringify(userId))}`)
        // }
        if (params?.agencyId && (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin')) {
            navigate(`/admin/profile-developer/${encodeURIComponent(JSON.stringify(userId))}?category=agenciesDeveloper`)
        } else if (!params?.agencyId && (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin')) {
            navigate(`view/${encodeURIComponent(JSON.stringify(userId))}?category=inHouse`)
        } else if (!params?.agencyId && authentication?.userRole === 'agency') {
            navigate(`view/${encodeURIComponent(JSON.stringify(userId))}`)
        }
    }

    const handleDelete = async () => {
        await axiosInstanceWithToken.post(`${BServer}/agency/developer/${userId} `)
        await axiosInstanceWithToken.post(`${BServer}/agency/developer/${userId}`)
            .then((res) => {
                console.log('res delete', res)
                toast.success(res?.data?.message)
                if (authentication?.userRole === 'agency') {
                    dispatch(viewAgencyAllDeveloperAction(authentication?.userId))

                } else if (authentication?.userRole === 'admin' || authentication?.userRole === 'sAdmin') {
                    dispatch(viewInHouseAllDeveloperAction())

                }
            })
    }
    return (
        <div className={styles.MyDeveloperCard} >
            {experience && experience > 0 ?
                <div className={`${styles.ribbon} ${styles.ribbon_top_right} `}><span>{experience}+ yrs exp</span></div>
                : null}
            <div className={styles.MyDeveloperCardImage}>
                <img src={imgsrc} alt=""
                    onClick={handleNavigate} />
            </div>
            <div className={styles.MyDeveloperCardDetail}>
                <h2 onClick={() => navigate('view', { state: { userId: userId } })} >{name}</h2>

                {desgination && <p>{desgination}</p>}
                <div className={styles.MyDeveloperCardSkill}>
                    {skill && skill.slice(0, 3)?.map(sk => (
                        <span key={sk?.name} >{sk?.name}</span>
                    ))}
                    {
                        skill?.length > 3 &&
                        <span  >+{skill?.length - 3}</span>
                    }
                </div>
                <p>{email}</p>
                <p> <small> <IoLocationSharp size={"14px"} /></small>{address}</p>
                <div className={styles.MydeveloperCardActions}>
                    <div className={styles.MyDeveloperCardDetailSocial}>
                        <a href={`//${github}`} target="_blank"><FaGithub /></a>
                        < a href={`//${linkedin}`} target="_blank" > <FaLinkedin /></a >
                    </div >
                    {!params?.agencyId &&
                        <div className={styles.MyDeveloperCardDetailSocial}>
                            <button onClick={() => navigate('edit', { state: { userId: userId } })}>
                                <FaRegEdit />
                            </button>
                            <button onClick={() => handleDelete()}><MdDelete /></button>
                        </div>
                    }
                </div >

            </div >
        </div >
    )
}

export default MyDeveloperCard;