import * as Yup from 'yup'
import {nameValueJobPost} from '../../initialValues/jobPost'
export const jobPostValidation =Yup.object().shape({
    [nameValueJobPost.title]:Yup.string().test('','Must be less than 75 character',(value)=>value.length<=75).required('Title is mandatory'),
    
    [nameValueJobPost.description]:Yup.string().required('Description is mandatory'),
    
    [nameValueJobPost.openingType]:Yup.object().test('','Invalid opening type',(value)=>Object.keys(value)?.length).required('Opening type is mandatory'),
    
    [nameValueJobPost.openings]:Yup.number().test('','Must not be 0',(value)=>value>=1).required('Opening is mandatory'),
    
    [nameValueJobPost.domaiOfExpertise]:Yup.object().test('','Invalid domain of expertise',(value)=>Object.keys(value)?.length).required('Domain of expertise is mandatory'),
    
    [nameValueJobPost.skills]:Yup.array().test('must','Select min 5 skills',(value=>value.length>=5)).test('must','Select upto 15 skills',(value=>value.length<=15)).required('Skills is mandatory'),
    
    [nameValueJobPost.totalExperienceInYear]:Yup.object().test('','Please select experience',(value)=>Object.keys(value)?.length).required('Total experience in year is mandatory'),
    
    [nameValueJobPost.rate]:Yup.object().test('','Invalid rate',(value)=>Object.keys(value)?.length).required('Rate is mandatory'),
    
    [nameValueJobPost.min]:Yup.number().test('','Must not be 0',(value)=>value>0).required('Min is mandatory'),
    
    [nameValueJobPost.max]:Yup.number().test('','Must not be 0',(value)=>value>0).required('Max is mandatory'),
    
    [nameValueJobPost.start]:Yup.string().required('Start date is mandatory'),
    
    [nameValueJobPost.end]:Yup.string().required('End date is mandatory'),
    
    [nameValueJobPost.location_country]:Yup.object().test('','Please select country',(value)=>Object.keys(value)?.length).required('Country is mandatory'),
    [nameValueJobPost.location_state]:Yup.object().test('','Please select state',(value)=>Object.keys(value)?.length).required('State is mandatory'),
    [nameValueJobPost.location_district]:Yup.object().test('','Please select district',(value)=>Object.keys(value)?.length).required('District is mandatory'),
    [nameValueJobPost.location_city]:Yup.object().test('','Please select city',(value)=>Object.keys(value)?.length).required('City is mandatory'),
    
    [nameValueJobPost.emailTo]: Yup
    .string()
    .email('Email is invalid')
    .test('Email is invalid', (value) => {
        return value.split('@')[1]?.includes('.')
    })
    .required('Email is required'),
    
    // NUmber validation
    // [nameValueJobPost.max]:Yup.number().required('Max is mandatory'),
    
    [nameValueJobPost.jobType]:Yup.object().test('','Invalid job type',(value)=>Object.keys(value)?.length).required('Job type is mandatory'),
    [nameValueJobPost.shiftType]:Yup.object().test('','Invalid shift type',(value)=>Object.keys(value)?.length).required('Shift type is mandatory'),
    [nameValueJobPost.visibility]:Yup.object().test('','Please select job visibility',(value)=>Object.keys(value)?.length).required('Visibility is mandatory'),
    
    // benefits validation
    // [nameValueJobPost.benifits]:Yup.array().test('must',(value=>value.length>0)).test('must','You can select upto 3 skills',(value=>value.length<4)).required('Skills is mandatory'),
})

