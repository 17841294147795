import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/common/landingPage/LandingPage';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import LoginPage from './pages/common/loginPage/LoginPage';
import SignupPage from './pages/common/signupPage/SignupPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigationbar from './components/common/navbar/Navigationbar';
import JobListing from './pages/common/jobListing/JobListing';
import Protected from './components/common/protected/Protected';
import Candidates from './pages/forAdmin/candidates/Candidates'
import MyDeveloper from './pages/common/developer/myDeveloper/MyDeveloper'
import ViewDeveloper from './pages/common/developer/viewDeveloper/ViewDeveloper'
import AddDeveloper from './pages/common/developer/addDeveloper/AddDeveloper'
import Agencies from './pages/forAdmin/agencies/Agencies'
import Footer from './components/common/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CreateProfileDev from './pages/forDeveloper/profilePage/createProfile/CreateProfileDev';
import ViewProfileDev from './pages/forDeveloper/profilePage/viewProfile/ViewProfileDev';
import CreateProfileAgency from './pages/forAgency/profilePage/createProfile/CreateProfileAgency';
import ViewProfileAgency from './pages/forAgency/profilePage/viewProfile/ViewProfileAgency';
import CreateProfileAdmin from './pages/forAdmin/profilePage/createProfile/CreateProfileAdmin';
import ViewProfileAdmin from './pages/forAdmin/profilePage/viewProfile/ViewProfileAdmin';
import ResetPassword from './pages/common/resetPassword/ResetPassword';
import Unauthorized from './pages/common/unauthorized/Unauthorized';
import UploadProgressBar from './components/common/childA/uploadProgressBar/UploadProgressBar';
import WithoutLogin from './components/common/protected/WithoutLogin';
import { viewProfileDevAction } from './Redux/actions/profile/viewProfile/viewProfileDevAction';
import { viewProfileAgencyAction } from './Redux/actions/profile/viewProfile/viewProfileAgencyAction';
import Loader from './components/common/childC/loader/Loader';
import AppliedUsersInAJob from './pages/forAdmin/jobs/appliedUsers/AppliedUsersInAJob';
import JobPostForm from './pages/forAdmin/jobs/JobPostForm/JobPostForm';
import { globalModal } from './Redux/actions/globalModal';
import MyAdmins from './pages/forAdmin/myAdmins/MyAdmins';
import { formDropdown } from './Redux/actions/form/formDropdown';
import HireDeveloperPage from './pages/common/hiredevelopersPage/HireDeveloperPage';
import ProvideEmailForVerification from './pages/common/emailVerification/ProvideEmailForVerification';
import EmailVerification from './pages/common/emailVerification/EmailVerification';
import Landing from './components/common/protected/Landing';
import ViewClientList from './pages/forAdmin/myAdmins/ViewClientList';
import Partners from './pages/common/partners/Partners';
import BenchResources from './pages/common/BenchResources/BenchResources';
import AppliedJob from './pages/common/appliedJob/AppliedJob';
import GlobalModal from './components/common/globalModal/GlobalModal';


function App() {
  // const userRole = useSelector((state) => state?.authentication?.userRole)
  const loading = useSelector((state) => state?.authentication?.loading)
  const loadingAxios = useSelector((state) => state.loadingAxios?.loading)
  const [isSignupSignin, setIsSignupSignin] = useState(false)
  const dispatch = useDispatch()
  // const isSignupSignin = ['/signup', '/signin'].includes(window.location.pathname)
  useEffect(() => {
    setIsSignupSignin(['/signup', '/signin'].includes(window.location.pathname))
  }, [window.location.pathname])

  useEffect(() => {
    dispatch(formDropdown())
    // dispatch(globalModal({ component: 'VerifyMobile', closeButton: true,  }))
  }, [])
  return (
    <div className="App container-fluid  m-0 p-0">
      <ToastContainer position="top-right"
        autoClose={1000}
        pauseOnHover={false}
        theme="light"
      />
      <BrowserRouter>
      <GlobalModal>
        <UploadProgressBar />
        {
          loading &&
          // <h1>Loading...</h1>
          <Loader />

        }
        {
          loadingAxios &&
          <Loader />
        }
        <Routes>


          {/* <Route path='/' element={<LandingPage />} /> */}

          <Route path='/' element={<Landing />}>
            <Route path='/' element={<LandingPage />} />
            <Route path='/partner' element={<Partners />} />
            <Route path='/hot-job' element={<JobListing />} />
            <Route path='/hire-developer' element={<HireDeveloperPage />} />
            <Route path='/apply-job' element={<JobListing />} />
            <Route path='/bench' element={<BenchResources />} />

          </Route>


          <Route path='/' element={<WithoutLogin />}>

            <Route path='signin' element={<LoginPage />} />

            <Route path='signup' element={<SignupPage />} />

            <Route path='provide-email' element={<ProvideEmailForVerification />} />

            <Route path='email-verification/:secretKeyFromParams' element={<EmailVerification />} />

            {/* http://localhost:5000/email-verification/DEV3992092211707580712291null */}

          </Route>


          <Route path='/reset-password' element={<ResetPassword />} />

          {/* only for developer or candidate access */}

          <Route path='/developer/' element={<Protected allowedUserRole={['developer']} />}>
            <Route path='' element={<ViewProfileDev />} />

            <Route path='jobListing' element={<JobListing />} />

            <Route path='candidates' element={<Candidates />} />

            <Route path='profile'>
              <Route path='create' element={<CreateProfileDev />} />
              <Route path='view' element={<ViewProfileDev />} />
              <Route path=':edit' element={<CreateProfileDev />} />
            </Route>

            <Route path='job'>

              <Route path='' element={<JobListing />} />
              <Route path='applied' element={<AppliedJob />} />


            </Route>

            {/* <Route path='profile/:create' element={<CreateProfileDev />} />
            <Route path='profile/:view' element={<ViewProfileDev />} /> */}


            <Route path='view-profile' element={<ViewProfileDev />} />

          </Route>

          {/* only for agency access */}

          <Route path='/agency/' element={<Protected allowedUserRole={['agency']} />}>

            <Route path='' element={<ViewProfileAgency />} />

            <Route path='profile'>
              <Route path='create' element={<CreateProfileAgency />} />
              <Route path='view' element={<ViewProfileAgency />} />
              <Route path=':edit' element={<CreateProfileAgency />} />

            </Route>

            <Route path='job'>

              <Route path='' element={<JobListing />} />
              <Route path='applied' element={<AppliedJob />} />


            </Route>

            <Route path='my-developer'>
              <Route path='' element={<MyDeveloper />} />
              <Route path='create' element={<CreateProfileDev />} />
              <Route path='view/:userId' element={<ViewProfileDev />} />
              <Route path=':edit' element={<CreateProfileDev />} />


            </Route>





            <Route path='jobListing' element={<JobListing />} />

            {/* <Route path='create-profile' element={<CreateProfileAgency />} /> */}

            {/* <Route path='view-profile' element={<ViewProfileAgency />} /> */}

            <Route path='my-developer-list' element={<MyDeveloper />} />

            <Route path='my-developer-view' element={< ViewDeveloper />} />

            <Route path='my-developer-add' element={<AddDeveloper />} />

          </Route>

          {/* only for admin access */}

          <Route path='/admin/' element={<Protected allowedUserRole={['admin', 'sAdmin']} />}>

            <Route path='' element={<ViewProfileAdmin />} />

            <Route path='profile'>

              <Route path='create' element={<CreateProfileAdmin />} />
              <Route path='view' element={<ViewProfileAdmin />} />
              <Route path=':edit' element={<CreateProfileAdmin />} />

            </Route>

            {/* job related */}

            <Route path='job'>
              <Route path='create' element={<JobPostForm />} />
              <Route path=':edit' element={<JobPostForm />} />
              <Route path='all' element={<JobListing />} />
              <Route path='' element={<JobListing />} />
              <Route path='applied-user/:_id/:jobId/:userRole' element={<AppliedUsersInAJob />} />

            </Route>

            {/* profile related */}


            {/* <Route path='my-developer/:inHouse'> */}

            <Route path='my-developer'>
              <Route path='' element={<MyDeveloper />} />
              <Route path='create' element={<CreateProfileDev />} />
              <Route path='view/:userId' element={<ViewProfileDev />} />
              <Route path=':edit' element={<CreateProfileDev />} />
            </Route>


            {/* candidates related */}

            <Route path='candidate' element={< Candidates />} />

            {/* agencies related */}

            <Route path='agencies' element={< Agencies />} />

            {/* our client */}

            <Route path='client' element={<ViewClientList />} />



            {/* developer list related */}

            <Route path='my-developer-list' element={<MyDeveloper />} />

            <Route path='my-developer-view' element={< ViewDeveloper />} />

            <Route path='my-developer-add' element={<AddDeveloper />} />

            <Route path='my-admin' element={<MyAdmins />} />
            <Route path='profile-developer/:userId' element={<ViewProfileDev />} />
            <Route path='profile-agency/:userId' element={<ViewProfileAgency />} />
            <Route path='profile-admin/:userId' element={<ViewProfileAdmin />} />
            <Route path='agencies-developer/:agencyId' element={<MyDeveloper />} />





          </Route>

          {/* <Route path='/sAdmin/' element={<Protected allowedUserRole={['sAdmin']} />}>

            <Route path='my-admin' element={<MyAdmins />} />
          </Route> */}





          <Route path='/main' element={<CreateProfileDev />} />
          <Route path='/jobListing' element={<ViewClientList />} />
          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='/testing' element={<ViewClientList />} />
          <Route path='/testingoneone' element={<AppliedUsersInAJob />} />
          <Route path='/testhire' element={<HireDeveloperPage />} />
          <Route path='*' element={<h1>The route not exists</h1>} />


        </Routes>
        {/* <Footer/> */}
     
        </GlobalModal>
           </BrowserRouter>
    </div>
  );
}

export default App;
