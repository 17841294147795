import React, { useEffect } from "react";
import styles from "./ViewProfileAgency.module.css";
import { useDispatch, useSelector } from "react-redux";
import { viewProfileAgencyAction } from "../../../../Redux/actions/profile/viewProfile/viewProfileAgencyAction";
import { BServer } from "../../../../server/backend";
// For react icon
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt, FaUsers, FaLinkedin } from "react-icons/fa";
import { PiSuitcaseSimpleFill } from "react-icons/pi";
import { FiGlobe } from "react-icons/fi";
import { formatLocalizedDateDifference } from "../../../../assets/functions/formatLocalizedDateDifference";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

const ViewProfileAgency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const authentication = useSelector((state) => state?.authentication)
  const params = useParams()
  const userId = params?.userId ? decodeURIComponent(JSON.parse(params?.userId)) : authentication?.userId
  const { loading, profileData } = useSelector(
    (state) => authentication?.userRole === 'agency' ? state?.myProfile : state?.viewProfileAgency);
  useEffect(() => {
    dispatch(viewProfileAgencyAction(userId));
  }, []);
  // console.log('params', params?.userId)
  // console.log('authentication', authentication?.userId)

  return (
    <div className={"container-fluid pt-5 pb-5"}>
      <div
        className={`row  d-flex align-items-center justify-content-center p-0`}
      >
        {/* Agency profile section */}
        <div className={`col-10 ${styles.item} `}>
          <div className={styles.agency_profile}>
            <div className={styles.profile_pic_dummy} >
              <div className={styles.profile_pic} >
                <img src={`${BServer}/uploads/${profileData?.personalInformation?.profilePicture}`} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
              </div>
            </div>
            <div className={styles.agency_details}>
              <div className={styles.agency_details_info}>
                <div className={styles.agency_detail_info_data}>
                  <h2 style={{display:"flex", alignItems:"flex-end" ,margin:"0"}} >{profileData?.personalInformation?.fullName} &nbsp; <p style={{paddingBottom:"3px"}} ><i>({profileData?.userId?.memberId})</i></p> </h2>
                  
                  <p style={{padding:"6px 0px"}} >{profileData?.personalInformation?.description?.slice(0, 45)}{profileData?.personalInformation?.description?.length > 45 && `...`}</p>
                </div>
                <div className={styles.agency_detail_info_lastupdate}>
                  <h4>Last updated: {formatLocalizedDateDifference(new Date(profileData?.lastProfileUpdated)?.toLocaleString())}</h4>
                </div>
              </div>

              <div className={styles.agency_information_sec}>

                <div className={`${styles.agency_info}`}>
                  <div className={styles.agency_info_basic_info} >
                    <FaLocationDot />
                    <h4>
                      {`${profileData?.location?.location_state?.name}, ${profileData?.location?.location_country?.name}`}
                    </h4>
                  </div>
                  <div className={styles.agency_info_basic_info} >
                    <FaUsers />
                    <h4>
                      {profileData?.overview?.overview_companySize?.name}
                      employees
                    </h4>
                  </div>
                  <div className={styles.agency_info_basic_info} >
                    <PiSuitcaseSimpleFill />
                    <h4>
                      {profileData?.overview?.overview_industrialExperience}
                      years
                    </h4>
                  </div>
                </div>
                <div className={styles.agency_info}>
                  <div className={`${styles.agency_info_basic_info} ${styles.responsive100width}`} >
                    <FaPhoneAlt />


                    <h4>
                      {
                        profileData?.userId?.mobile ?
                          profileData?.userId?.mobile
                          :
                          `+91-No data`
                      }
                    </h4>

                  </div>
                  <div className={styles.agency_info_basic_info} >
                    <i className="fa fa-solid fa-envelope"></i>

                    <h4> <a href={`mailto:${profileData?.userId?.email}`}>{profileData?.userId?.email}</a></h4>
                  </div>
                  <div className={styles.agency_info_basic_info} >
                    <FiGlobe />
                    <h4 >
                      <a href={`//${profileData?.social?.social_website}`} target="_blank" >
                        {profileData?.social?.social_website}
                      </a>
                      {/* <NavLink to='www.google.com' target="_blank">
                        {profileData?.social?.social_website}

                      </NavLink> */}

                    </h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div
          className={`col-10 d-flex ${styles.secondcontainer}`}
          style={{ gap: "13px", paddingLeft: "0px", paddingTop: "14px" }}
        >

          {/* address section start */}
          <div className={`col-4 ${styles.item} ${styles.responsive100width}`} style={{ height: "auto", padding:"30px" }}>
            <h5 style={{ marginTop: "10px", marginBottom: "8px" }}>Address</h5>

            <div className={styles.add_details}>
              <p> <span> Address:</span> {profileData?.location?.location_address}</p>
              <p> <span> City:</span> {profileData?.location?.location_city?.name}</p>
              <p> <span> Pincode:</span> {profileData?.location?.location_pinCode}</p>
              <p> <span> District:</span> {profileData?.location?.location_district?.name}</p>
              <p> <span> State:</span> {profileData?.location?.location_state?.name}</p>
              <p> <span> Country:</span> {profileData?.location?.location_country?.name}</p>
            </div>
            <h5 className="mt-3">Social Links</h5>
            <div className={`${styles.social_links1} `} >
              <FaLinkedin />
              <a href={`//${profileData?.social?.social_linkedin}`} target="_blank" >
                {profileData?.social?.social_linkedin}
              </a>

            </div>

            <hr size="1" color="#dadada" />
            <h2 style={{ fontSize: "20px", marginBottom: "8px", fontWeight: "200" }} >
              Quick Links
            </h2>
            {!params?.userId &&
              <div
                className={styles.quick_links}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p style={{color:'#696969'}} >Personal Details</p>
                <h5 style={{ color: "#6501a3" }} onClick={() => navigate('/agency/profile/edit')}>Update</h5>
              </div>
            }
            <div
              className={`${styles.quick_links} ${styles.responsive100width}`}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p onClick={() => navigate(authentication?.userRole === 'agency' ?
                '/agency/my-developer'
                :
                `/admin/agencies-developer/${profileData?.userId?._id}`
                )}>
                My Developer {`(${profileData?.myDevelopers?.count})`}
              </p>
              {!params?.userId &&
                <h5 style={{ color: "#6501a3" }} onClick={() => navigate('/agency/my-developer/create')}>Add Developer</h5>

              }
            </div>

            {/* <div
              className={styles.quick_links}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p>Applied Jobs</p>
              <h5 style={{ color: "#6501a3" }}>View</h5>
            </div> */}
            {/* <div
              className={styles.quick_links}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p>Feedback</p>
              <h5 style={{ color: "#6501a3" }}>Sent</h5>
            </div> */}

          </div>
          {/* area of expertise */}
          <div className={`col-8 d-flex flex-column ${styles.responsive100width}`} style={{ gap: "15px"  }}>
            <div className={` ${styles.item}  `} style={{  padding:"30px" }} >
              <h2 style={{ fontSize: "20px", marginTop: "10px", fontWeight: "200" }}>
                Area of Expertise
              </h2>
              <div
                className={styles.contact_details}
                style={{ display: "flex" }}
              >
                {profileData?.overview?.overview_areaOfExpertise?.map(
                  (item, index) => (
                    <div key={index} className={styles.techs1}>
                      <p>{item.name}</p>
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Upload document */}
            <div className={`${styles.item} col-12 `} style={{ padding:"30px" }} >
              <div className="grid__item grid__item_item4">
                <div className={styles.agency_profile_aboutus}  >
                  <h2
                    style={{
                      fontSize: "20px",
                      marginBottom: "8px",
                      marginTop: "15px",
                      fontWeight: "200"
                    }}
                  >
                    Company Description
                  </h2>
                  <p>{profileData?.personalInformation?.description}</p>
                </div>
                <h2
                  style={{
                    fontSize: "20px",
                    marginBottom: "8px",
                    marginTop: "15px",
                    fontWeight: "200"
                  }}
                >
                  Uploaded Documents
                </h2>



                <div className={styles.agencyDocument}>
                  <h6 className={styles.agencybugcolor} >Registration Certificate : &nbsp;</h6>
                  <a href={`${BServer}/uploads/${profileData?.documents?.documents_registration}`} target="blank">
                    <h6>{profileData?.documents?.documents_registration}</h6>
                  </a>
                </div>

                <div className={styles.agencyDocument}>
                  <h6 className={styles.agencybugcolor} >Pan Card : &nbsp;</h6>
                  <a href={`${BServer}/uploads/${profileData?.documents?.documents_panCard}`} target="blank">
                    <h6>{profileData?.documents?.documents_panCard}</h6>
                  </a>
                </div>

                <div className={styles.agencyDocument}>
                  <h6 className={styles.agencybugcolor} >GST Certificate : &nbsp;</h6>
                  <a href={`${BServer}/uploads/${profileData?.documents?.documents_gstCertificate}`} target="blank">
                    <h6>{profileData?.documents?.documents_gstCertificate}</h6>
                  </a>
                </div>
                {profileData?.documents?.otherDocuments?.length > 0 &&
                  profileData?.documents?.otherDocuments?.map(otherDocument => {
                    return (
                      <div className={styles.agencyDocument}>
                        <h6 className={styles.agencybugcolor} >{otherDocument.name.charAt(0).toUpperCase() + otherDocument.name.slice(1)} : &nbsp;</h6>
                        <a href={`${BServer}/uploads/${otherDocument.value}`} target="blank">
                          <h6>{otherDocument.value}</h6>
                        </a>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfileAgency;
