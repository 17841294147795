import React, { memo, useState } from 'react'
import styles from './CreateProfileDev.module.css'
const LocationDropdown = ({ label, values, required, name, setFieldValue, onBlur, error, previousValue, value }) => {

    const [state, setState] = useState()
    const handleChange = (e) => {
        // console.log('weeeee',e.target.name,e.target.value)
        if (e.target.value !== 'select') {
            setFieldValue(name, values.filter((i) => i.name == e.target.value)?.[0])
        }
    }
    return (

        <div className={styles.ProfilePageDropdownFeild}>
            <label htmlFor={label}>
                {label}
                {
                    required && <span> *</span>
                }
            </label>
                            {/* {previousValue &&
                    <span className='m-0 p-0 ' style={{fontSize:'.8rem'}}>{`[${previousValue.name}]`}</span>
                } */}
            <select name={name} onChange={handleChange} onBlur={onBlur} value={value && value.name}>
                <option value={'select'}>Select</option>


                {values?.map(item => {
                    return (
                        <option value={item?.name} key={`${item?.name}${item?.geonameId}`}>{item?.name}</option>
                    )
                })}
            </select>
            {error &&
                <div className={styles.ProfilePageInputfield_error_msg}>{error}</div>
            }
        </div>

    )
}

export default memo(LocationDropdown)