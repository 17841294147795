import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "../landingPage/components/Carousel/DevCarousel/DevCarousels.jsx";
import styles from "./LandingPage.module.css";
import "./LandingPage.module.css";
import hiring2 from "../../../assets/images/hiring.png";
import carilogo from "../../../assets/images/cari-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { globalModal } from "../../../Redux/actions/globalModal";
import Clientele from "../../common/landingPage/components/Clientele.jsx";
import DevCarousels from "../landingPage/components/Carousel/DevCarousel/DevCarousels.jsx";
import Slider from "react-slick";
import HeadingTag from "../../../components/common/childC/headingTag/HeadingTag.jsx";
import starimg from "../../../assets/images/star1.png";
import starimg2 from "../../../assets/images/star2.png";
import client1 from "../../../assets/images/cl1.png";
import client2 from "../../../assets/images/cl2.png";
import client3 from "../../../assets/images/cl3.png";
import client4 from "../../../assets/images/cl4.png";
import client5 from "../../../assets/images/cl5.png";
import client6 from "../../../assets/images/cl6.png";
import { Button, Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import react1 from "../../../assets/images/react1.png";
import java1 from "../../../assets/images/java1.png";
import jsimages1 from "../../../assets/images/javascript1.png";
import node1 from "../../../assets/images/node1.png";
import android1 from "../../../assets/images/android1.png";
// import Clientele from './components/Clientele.jsx'
import bsnssimg from "../../../assets/images/business-img1.jpg";
import devesimg from "../../../assets/images/developer-img1.jpg";
import { Link, useNavigate } from "react-router-dom";
// import { detectOverflow } from '@popperjs/core';

import CarivaTechnologiesResources from "./components/CTMethod/CarivaTechnologiesResources.jsx";
import HiringMethod from "./components/CTMethod/HiringMethod.jsx";
import TechStackSearch from "./components/CTMethod/TechStackSearch.jsx";
import Industries from "./components/industries/Industries.jsx";
import { allJobAction } from "../../../Redux/actions/job/allJobAction.js";
import accreditation from '../../../../src/assets/images/only-accree12.png'

// dispatch(globalModal({component:'ContactForm',closeButton:false,size:'lg'}))

const LandingPage = () => {
  const dispatch = useDispatch();
  const allJob = useSelector((state) => state?.allJob);
  const navigate=useNavigate()
  useEffect(() => {
    dispatch(allJobAction({
      input: "",
      jobType: "",
      totalExperienceInYear: "15+",
      skills: "",
      pageNumber: 1,
      pageSize: 10
    }));

  }, []);
  return (
    <>
      <div className={`${styles.landingPage_innr} row`}>
        <div className="col-7 mt-5">
        <h2 className={styles.scnd_head}>Empowering Business Through</h2>
        <h1 className={styles.frst1_head}>Hire Talents Perfectly Matched By AI, <span id={styles.home_gradient2}>5X Faster!</span></h1>
        

        <p className={styles.para_btm1}>
          Your one-stop shop for all things related to <strong style={{color:'#000'}}>recruitment & staffing.</strong>   
           <br/>Let Talnfinium to take your needs & business to the next
          level.
        </p>

        <button
          className={styles.banner_btn}
          onClick={() => {
            dispatch(
              globalModal({
                component: "ContactForm",
                closeButton: true,
                size: "lg",
              })
            );
          }}
        >
          Hire Now - It's Free
        </button>
        <a href="http://localhost:5000/javahire">
        <button className={styles.banner_btn22}>Contact Us</button>
        </a>

        {/* <div className={`d-flex  ${styles.reqtitle} `}>
          <img
            src={hiring2}
            alt="Md Mudassar"
            style={{
              color:'#000',
              width: "2rem",
              height: "2rem",
              marginTop: "2.5%",
              marginRight: "5px",
            }}
          />
          <h3 className={styles.thrd1_head}>Ongoing Requirements !</h3>
        </div> */}
        <div className={styles.dev_carousel}>

        </div>
        </div>
        <div className={`${styles.accre_banner} col-5`}>
          <img src={accreditation} width={'90%'} alt="Md Mudassar" />
          <div className={styles.awards}>
          <h1 className={styles.animate_charcter}>2024</h1>
          <h2 className={styles.animate_charcter1}>Awards</h2>
          </div>
        </div>
      </div>

      <div className={styles.client_sec}>
        {/* <h1>Our Clientele</h1> */}
        <Clientele />
      </div>

      <div className={`mt-3`} style={{ backgroundColor: "rgb(245 245 245)" }}>
        <div className="row">
          <div className={`${styles.business} col-6`}>
            <img
              src={bsnssimg}
              alt="Md Mudassar"
              style={{
                width: "90%",
                padding: "45px 55px",
                borderRadius: "70px",
              }}
            />
          </div>
          <div className={`col-6 p-5 ${styles.about_details}`}>
            <h5>For Businesses</h5>
            <h2>
              Hire faster with the world's most deeply vetted developers and
              teams, selected by Talnfinium.
            </h2>
            <p>
              Quickly and efficiently build your engineering team with our
              deeply vetted talents from Talnfinium.
            </p>
            <button
              className={styles.hire_buttons}
              onClick={() => {
                dispatch(
                  globalModal({
                    component: "ContactForm",
                    closeButton: true,
                    size: "lg",
                  })
                );
              }}
            >
              Hire Talents
            </button>
          </div>
        </div>
      </div>

      <div className={styles.developer}>
        <div className="row">
          <div className={`col-6 p-5 ${styles.about_details}`}>
            <h5>For Developers</h5>
            <h2>Get Top Indian Companies to high-paying U.S. tech jobs</h2>
            <p>
              Quickly and efficiently build your engineering team with our
              deeply vetted talent.
            </p>
            <a href="/apply-job">
              <button className={styles.hire_buttons}>Find Jobs</button>
            </a>
          </div>
          <div className={`${styles.business} col-6`}>
            <img
              src={devesimg}
              alt="Md Mudassar"
              style={{
                width: "90%",
                padding: "45px 55px",
                borderRadius: "70px",
              }}
            />
          </div>
        </div>
      </div>

      <HiringMethod />

      <CarivaTechnologiesResources/>

      {/* <CarivaTechnologiesResources /> */}

      {/* <div
        style={{
          backgroundColor: "#fff",
          margin: "30px 0",
          padding: "30px 0 0 0",
        }}
      >
        <div className="text-center">
          <h1>Find Your Dream Job</h1>
        </div>
        <div className={styles.serchbarhome}>
          <input
            type="text"
            id="search"
            className={styles.inptsearchh}
            placeholder="Enter a job title..."
          />
          <input
            type="text"
            id="search"
            className={styles.inptsearchh}
            placeholder="Enter a city..."
          />
         <a href="/hot-job"><button className={styles.btnsearchh}>Search Now</button></a> 
        </div>
      </div> */}

      <div className={`mb-5 ${styles.mainsecjobs}`}>
        <h1 className="text-center mb-5 mt-5">Career Opportunities</h1>

        <div className="d-flex flex-wrap">



          {!allJob?.loading &&
            (allJob?.length > 0 ? (
              allJob?.jobs?.map((item, index) => index <= 5 &&
                <div className="col-4" key={item?._id} data={item}>
                  <div className={styles.cards}>
                    <div className={styles.cardsUpper}>
                      <div className={`${styles.card_details} d-flex`}>
                        <div className={"styles.dev_details"}>
                          <h5 className={styles.mainjobtitle}>
                            {item?.basic?.title?.slice(0, 30)}
                            {item?.basic?.title?.length > 30 && `...`} <br></br>
                          </h5>
                          <p
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              padding: "0",
                            }}
                          >
                            Experience:{" "}
                            <strong style={{ color: "#000", fontWeight: "500" }}>
                              {item?.profession?.totalExperienceInYear?.name} years
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.hire_details}>
                      <div className={styles.dev_exp2}>
                        <p>
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            style={{ color: "#000", marginRight: "4px" }}
                          />
                          {`${item?.location?.location_city?.name}, ${item?.location?.location_state?.name}`}
                        </p>
                      </div>
                    </div>
                    <div className="hire-btnn">
                      <button className={styles.hire_btnhome} onClick={()=>navigate("/signin")}>Apply Now</button>
                    </div>
                  </div>
                </div>





              )
            ) :
              <h6>No Job Found</h6>

            )}






          
        </div>

        

        <div className="hire_btn2 text-center mt-5">
          <Link className={styles.hire_btn2} to="/hot-job">
            Get More Jobs
          </Link>
        </div>
      </div>

      <h1 style={{ textAlign: "center" }}>Benefits of Choosing Talnfinium</h1>
      <div
        className={`${styles.LandingPageTopDevelopers} justify-content-center mx-auto col-11`}
      >
        <div className={`${styles.DeveloperCardLandingPage} col-6`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
              <div className={`${styles.card_details} d-flex`}>
                <div className={"styles.dev_details"}>
                  <h5>Contract Hire </h5>
                </div>
              </div>
            </div>
            <div className={styles.dev_about}>
              <p>
                Explore our contract hire services for flexible solutions
                tailored to your business needs. From short-term projects to
                long-term partnerships.
                <br />
                <br />
                <b>1. Access all pre-vetted developers</b>
                <br />
                <b>2. Start in 36-48 hours</b>
                <br />
                <b>3. Pay hourly/monthly</b>
              </p>
            </div>
            <div className={styles.hire_details}>
              <div className="hire-btnn">
                <button
                  className={styles.hire1_btn}
                  onClick={() =>
                    dispatch(
                      globalModal({
                        component: "ContactForm",
                        closeButton: true,
                        size: "lg",
                      })
                    )
                  }
                >
                  Start Your Journey
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.DeveloperCardLandingPage} col-6`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
              <div className={`${styles.card_details} d-flex`}>
                <div className={"styles.dev_details"}>
                  <h5>Full Time Hire </h5>
                </div>
              </div>
            </div>
            <div className={styles.dev_about}>
              <p>
                Unlock the potential of your workforce with our full-time hiring
                solutions. Discover top-tier talent ready to propel your
                business forward. <br />
                <br />
                <b>1. 1-click for 100s of applicants</b>
                <br />
                <b>2. Pre-screened Candidates</b>
                <br />
                <b>3. Hire within 1 week</b>
              </p>
            </div>
            <div className={styles.hire_details}>
              <div className="hire-btnn">
                <button
                  className={styles.hire1_btn}
                  onClick={() =>
                    dispatch(
                      globalModal({
                        component: "ContactForm",
                        closeButton: true,
                        size: "lg",
                      })
                    )
                  }
                >
                  Start Today
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Industries />

      <div className={styles.compstry}>
        <h1 className="text-center mb-5 text-light">Talnfinium's Story</h1>
        <div className="d-flex">
          <div className="col-3">
            <h2 className="text-light">6000+</h2>
            <p className="text-light">Jobseekers have joined Talnfinium</p>
          </div>

          <div className="col-3">
            <h2 className="text-light">13+ Years</h2>
            <p className="text-light">Experience in providing services</p>
          </div>

          <div className="col-3">
            <h2 className="text-light">500+</h2>
            <p className="text-light">Candidates placed</p>
          </div>

          <div className="col-3 text-centre">
            <h2 className="text-light">110+</h2>
            <p className="text-light">Clients served</p>
          </div>
        </div>
      </div>

      {/* <TechStackSearch /> */}
    </>
  );
};

export default LandingPage;
