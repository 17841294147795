import React, { useEffect, useState } from 'react'
// import React, { useState } from 'react';
import './LoginPage.css'
import bgimage from '../../../assets/images/bg.jpg'
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import InputBootstrap from '../../../components/common/childB/inputBootstrap/InputBootstrap';
import { useFormik } from 'formik';
import { signinValidation } from '../../../assets/yupValidation/signinValidation';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationAction } from '../../../Redux/actions/authenticationAction';
import { globalModal } from '../../../Redux/actions/globalModal';
import { FaRegEye } from "react-icons/fa6";
import logocariva from '../../../assets/images/cari-logo.png'
import { Link, NavLink } from "react-router-dom"
import logo from '../../../assets/images/footer-logo.png'


const LoginPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const message = location?.state?.message
    const authentication = useSelector((state) => state?.authentication)
    // useEffect(() => {
    //     {
    //         authentication?.token &&(
    //             authentication?.userRole==='sAdmin'?
    //             navigate(`/admin`)
    //             :
    //             navigate(`/${authentication?.userRole}`)


    //         )

    //     }
    // }, [])
    const { values, handleChange, handleSubmit, handleBlur, resetForm, errors, touched } = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: signinValidation,
        onSubmit: async (values) => {
            dispatch(authenticationAction(values, (path) => {
                console.log('path', path)
                navigate(path)


            }))
        }
    })




    const handleLogin = (e) => {
        e.preventDefault()
        if (errors.email) {
            toast.error(errors.email)
        } else if (errors.password) {
            toast.error(errors.password)
        } else {
            handleSubmit()
        }

    }

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);


    return (
        <div className="container">
            {/* <input type="checkbox" id="flip" /> */}
            <div className="cover">
                <div className="front">
                    <img src={bgimage} alt="" />
                    <div className="text">
                        {/* <img src={logocariva} width={40}/> */}
                        <span className="text-1">Find Your Dream Jobs</span>
                        <span className="text-2">Dream Job. Dream Company. Dream Career</span>
                        <Link to='/' className='bakkbtn'>Take a Tour</Link>
                    </div>

                </div>
                <div className="back">
                    <div className="text">
                        <span className="text-1">Complete miles of journey <br /> with one step</span>
                        <span className="text-2">Let's get started</span>
                    </div>
                </div>
            </div>
            <div className="forms">
                <div className="form-content">
                    <div className="login-form">
                        <div className="image-section">
                            <img src="./assets/cari-logo.png" alt="" />
                        </div>
                        <div className="img-text">Welcome Back</div>
                        {
                            message &&
                            <span className='istmess'>{message}</span>
                        }

                        <div className="title ">Login</div>
                        {/* <form action="https://www.google.com">
                    <div className="input-boxes">
                        <div className="input-box">
                            <i className="fas fa-envelope"></i>
                            <input type="text" placeholder="Enter your email" required />
                        </div>
                        <div className="input-box">
                            <i className="fas fa-lock"></i>
                            <input type="password" placeholder="Enter your password" required />
                        </div>
                        <div className="text"><a href="https://www.google.com">Forgot password?</a></div>
                        <div className="button input-box">
                            <input type="submit" value="Submit" />
                        </div>
                        <div className="text sign-up-text">Don't have an account? <label><a
                                    href="https://www.google.com">Sigup
                                    now</a></label></div>
                    </div>
                </form> */}


                        <Form>
                            {/* <Form.Group className="mb-3" >
                                <Form.Label className='mt-3'>Email address</Form.Label>
                                <Form.Control type="email"
                                placeholder="name@example.com"
                                className='form-control'
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                />

                            </Form.Group> */}

                            <InputBootstrap
                                label={'Email address'}
                                type={'email'}
                                placeholder={"name@example.com"}
                                name={'email'} value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.email ? true : false}
                                className={'form-control, namecsss'}
                                errorClassName={'signup_error'}
                                error={touched.email && errors.email}
                            />

                            {/* <Form.Group className="mb-3" >
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" />
                            </Form.Group> */}

                            {/* <InputBootstrap
                                label={'Password'}
                                type={'password'}
                                placeholder={"********"}
                                name={'password'} value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={errors.password ? true : false}
                                className={'form-control'}
                                errorClassName={'signup_error'}
                                error={touched.password && errors.password}
                                // type={!visible ? "password" : "text"}
                                 /> */}

                                <InputBootstrap
                                    isPasswordFeild = {true}
                                    label={'Password'}
                                    id="pass"
                                    type={showPassword ? "text" : "password"}
                                    placeholder={"********"}
                                    name={'password'} value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.password ? true : false}
                                    className={'form-control, namecsss'}
                                    errorClassName={'signup_error'}
                                    error={touched.password && errors.password}
                                // type={!visible ? "password" : "text"}
                                />

                            {/* <label for="pass">Enter password: </label>
        <input
          id="pass"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br /> */}
                            {/* <input
                                id="check"
                                type="checkbox"
                                value={showPassword}
                                onChange={() => setShowPassword((prev) => !prev)}
                            />
                            <label for="check" className='px-1'>Show Password</label>
                            <br /> */}
                            {/* <label  className='px-1' onClick={()=>navigate('/provide-email')}>Forgot Password</label> */}
                            <label className='' onClick={() => dispatch(globalModal({ component: 'EmailVerificationModal', size: 'md' }))}>Forgot Password &nbsp; |&nbsp; &nbsp; </label>
                            <label className='' onClick={() => dispatch(globalModal({ component: 'LoginWithOtp', size: 'md' }))}>Login with OTP</label>


                        </Form>














                        <div className="signup-path">
                            <p className='mt-2'>Don't have an account ? &nbsp;<a href="/signup">Sign up</a></p>
                        </div>
                        <Button
                            variant=""
                            className='btn btn-login'
                            disabled={authentication?.loading && true}

                            onClick={handleLogin}
                        >
                            {authentication?.loading ? 'verifying credentials...' : 'Login'}
                            {authentication?.loading &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                        </Button>
                    </div>
                    {/* <div className="signup-form">
                <div className="title">Signup</div>
                <form action="https://www.google.com">
                    <div className="input-boxes">
                        <div className="input-box">
                            <i className="fas fa-user"></i>
                            <input type="text" placeholder="Enter your name" required />
                        </div>
                        <div className="input-box">
                            <i className="fas fa-envelope"></i>
                            <input type="text" placeholder="Enter your email" required />
                        </div>
                        <div className="input-box">
                            <i className="fas fa-lock"></i>
                            <input type="password" placeholder="Enter your password" required />
                        </div>
                        <div className="button input-box">
                            <input type="submit" value="Sumbit" />
                        </div>
                        <div className="text sign-up-text">Already have an account? <label for="flip">Login now</label>
                        </div>
                    </div>
                </form>


                
            </div> */}
                </div>
            </div>
        </div>
    )
}

export default LoginPage