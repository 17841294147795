import React, { useState, useRef, useEffect } from "react";
import UnitJobView from "../../../components/common/unitJobView/UnitJobView";
import styles from "./JobListing.module.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SearchBar from "../../../components/searchBar/SearchBar";
import CustomDropdown from "../../../components/common/childB/commonDropdown/CustomDropdown";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { PiCodesandboxLogoDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { allJobAction } from "../../../Redux/actions/job/allJobAction";
import { appliedJobAction } from "../../../Redux/actions/job/appliedJobAction";
import noprofileFound from "../../../assets/images/no-result-found.png"

const JobListing = () => {
  const dispatch = useDispatch()
  const allJob = useSelector((state) => state?.allJob)
  const Skills = useSelector((state) => state?.formDropdown?.techStacks)
  const { userId } = useSelector((state) => state?.authentication)
  const JobType = [{ name: "Hybrid", _id: "hybrid" }, { name: "Remote", _id: "remote" }, { name: "Onsite", _id: "onsite" }]
  const Experience = [{ _id: 'fresher', name: 0 }, ...(Array.from({ length: 15 }, (_, i) => ({ _id: `year${i + 1}`, name: i + 1 }))), { _id: 'year15Plus', name: '15+' }]
  const [SelectedSkill, setSelectedSkill] = useState([])
  const initialState = {
    input: '',
    jobType: '',
    totalExperienceInYear: '15+',
    skills: ''
  }
  const [parameters, setParameters] = useState(initialState)
  // const pageNumber=1
  const pageSize = 10
  const pageNumber = 1;


  const onChangeHandler = (e) => {
    setParameters(
      {
        ...parameters,
        [e.target.name]: e.target.name == 'skills' ?
          `${parameters?.skills}${parameters?.skills && ','}${e.target.value}`
          :
          e.target.value
      }
    )
    if (e.target.name == 'skills') {
      setSelectedSkill([
        ...SelectedSkill, e.target.value
      ])
    }
  }
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  const HandleSelectedSkillRemove = (e) => {
    setSelectedSkill(SelectedSkill.filter(each => each !== e))
    let inputString = parameters?.skills;
    let substringToRemove = e;
    let regex = new RegExp(escapeRegExp(substringToRemove) + ',?', 'g');
    inputString = inputString.replace(regex, '');
    setParameters(
      {
        ...parameters,
        skills: inputString
      }
    )
  }

  useEffect(() => {
    dispatch(allJobAction({ ...parameters, pageNumber, pageSize }))
    if (userId) {

      dispatch(appliedJobAction({ _id: userId }))
    }

  }, [])
  const handleSearch = () => {
    dispatch(allJobAction({ ...parameters, pageNumber, pageSize }))
  }
  const handleReset = () => {
    setParameters(initialState)
    setSelectedSkill([])
    handleSearch()
  }
  console.log('allJob', allJob)


  return (
    <div className="container-fluid p-0 mt-0 d-flex flex-column align-items-center" >
      <section className={styles.banner}>
        <h3>Requirement Details</h3>
        <div className={styles.searchbar}>
          <input type="text" placeholder="Enter City, state, ZIP code, or remote" name='input' value={parameters.input} onChange={onChangeHandler} />

          {/*  skills   */}
          <select name="skills" id="" onChange={onChangeHandler} >
            <option value={{ _id: 'fresher', name: 0 }} defaultValue>Select Skills</option>
            {Skills?.map((each) => {
              return (
                <option value={each.name} key={each?._id}>{each?.name}</option>
              )
            })}
          </select>
          {/* Total experience */}
          <select name='totalExperienceInYear' value={parameters?.totalExperienceInYear} onChange={onChangeHandler} >
            <option value={false} defaultValue>Experience</option>
            {Experience?.map((each) => (

              each.name == 0 ?
                <option value={each.name} key={each._id}>{`Fresher`}</option>
                :
                each.name == 1 ?
                  <option value={each.name} key={each._id}>{`${each.name} year`}</option>
                  :
                  <option value={each.name} key={each._id}>{`${each.name} years`}</option>


            )
            )}
          </select>

          {/* JobType type */}
          <select name="jobType" onChange={onChangeHandler} value={parameters.jobType}>
            <option value={false} defaultValue>Job Type</option>
            {JobType.map((each) => {
              return (
                <option value={each?._id} key={each._id}>{each.name}</option>
              )
            })}
          </select>

          <button onClick={handleSearch}>Search</button>
          <button onClick={handleReset} >Reset</button>


        </div>
        {SelectedSkill.length > 0 &&
          <div className={styles.SSelectedSkills}>
            <p>Searched for Skill</p>
            <div className={styles.selectedskillcont}>
              {SelectedSkill.map(each => {
                return (
                  <span  >{each} <IoIosRemoveCircleOutline onClick={() => HandleSelectedSkillRemove(each)} /></span>

                )
              })}
            </div>

          </div>
        }
      </section>




      {!allJob?.loading &&
        (allJob?.length > 0 ? allJob?.jobs?.map((item) => (
          <UnitJobView key={item?._id} data={item} />

        ))
          :
          <div className={styles.noProfilFound}>
            <img className={styles.noprofileFoundImage} src={noprofileFound} alt="" />
            <p >No Job Found</p>
          </div>)
      }



    </div>
  );
};

export default JobListing;
