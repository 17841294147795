import React from "react";
import styles from "../../LandingPage.module.css";
import indstry1 from '../../../../../../src/assets/images/online-shop.png'
import trans from '../../../../../assets/images/delivery-truck.png'
import educa from '../../../../../assets/images/education.png'
import finance from '../../../../../assets/images/finance.png'
import labor from '../../../../../assets/images/labor.png'
import aid from '../../../../../assets/images/first-aid-kit.png'
import automo from '../../../../../assets/images/car.png'
import bank from '../../../../../assets/images/bank.png'
import foods from '../../../../../assets/images/diet.png'
import travel from '../../../../../assets/images/destination.png'
import software from '../../../../../assets/images/programming.png'
import construc from '../../../../../assets/images/warning.png'


const industries = () => {
  return (
    <div>

      <div className="text-center mt-5 mb-3">
      <h1>Industries We Serve</h1>
      </div>
      
      <div
        className={`${styles.LandingPageTopDevelopers} justify-content-center mx-auto col-12`}
      >
        

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={indstry1} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>eCommerce </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={trans} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Transport </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={educa} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Education </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={finance} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Finance </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={labor} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Industrial </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={aid} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Healthcare </h5>
                </div>
            </div>
          </div>
        </div>





      </div>

      <div
        className={`${styles.LandingPageTopDevelopers} justify-content-center mx-auto col-12`}
      >
        

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={automo } alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Automobile </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={foods} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Food </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={software} alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Software </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={travel } alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Travel </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={construc } alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Construction </h5>
                </div>
            </div>
          </div>
        </div>

        <div className={`${styles.IndustryCardLandingPage} col-2`}>
          <div className={styles.cards}>
            <div className={styles.cardsUpper}>
                <div className={`${styles.dev_details} text-center`}>
                  <img src={bank } alt="" width={35} className={styles.indstry_img} />
                  <h5 className={styles.indstry_hdng}>Banking </h5>
                </div>
            </div>
          </div>
        </div>





      </div>






    </div>
  );
};

export default industries;
