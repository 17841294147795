import React, { useEffect } from 'react'
import style from './JobDetails.module.css'
import hotjobs from '../../../../../assets/images/fire-hot.png';
import { useDispatch, useSelector } from 'react-redux';
import { singleJobAction } from '../../../../../Redux/actions/job/singleJobAction';
import { formatLocalizedDateDifference } from '../../../../../assets/functions/formatLocalizedDateDifference';
import { useNavigate } from 'react-router-dom';

const JobDetails = () => {
    const dispatch = useDispatch()
    const data = useSelector((state) => state?.globalModal?.data)
    const authentication = useSelector((state) => state?.authentication)


    return (
        <div className="container-xxl wow fadeInUp" data-wow-delay="0.1s">
            <div className={style.container}>
                <div className="row">
                    <div className="col-lg-12 p-4 mb-3" style={{ borderRadius: '20px', boxShadow: '2px 2px 20px 2px #e7e7e7' }}>
                        <div className="d-flex align-items-center">
                            <div className="text-start ps-1">
                                <div className="hot-jobs d-flex">
                                    <h2 className="mb-3 me-2">{data?.basic?.title}</h2><img src={hotjobs} alt="hotjobs" width={40} className='mb-0 pb-3' />
                                </div>
                                <span className="text-truncate me-3" style={{ fontWeight: '500' }}>
                                    <i className="fa fa-map-marker-alt me-2" style={{ color: 'rgb(87 23 148)' }}></i>
                                    {`${data?.location?.location_country?.name}, 
                                    ${data?.location?.location_state?.name}, 
                                    ${data?.location?.location_district?.name}, 
                                    ${data?.location?.location_city?.name}`}
                                </span>
                                <span className="text-truncate me-3" style={{ fontWeight: '500' }}>
                                    <i className="far fa-clock me-2" style={{ color: 'rgb(87 23 148)' }}></i>
                                    {data?.others?.jobType?.name}
                                </span>
                                <span className="text-truncate me-0" style={{ fontWeight: '500' }}><i className="far fa-money-bill-alt me-2" style={{ color: 'rgb(87 23 148)' }}></i><span style={{ fontSize: '17px' }}>&#8377;</span> {`${data?.amount?.min} - ${data?.amount?.max} ${data?.amount?.rate?.name}`}</span>

                            </div>
                        </div>
                        <div className="exp-details mt-2 mx-1 mb-3">
                            <span className="text-truncate me-4" style={{ fontWeight: '500' }}>
                                <i class="fa fa-regular fa-briefcase me-2" style={{ color: 'rgb(87 23 148)' }}></i>
                                {`${data?.profession?.totalExperienceInYear?.name} y`}
                            </span>
                            <span className="text-truncate me-0" style={{ fontWeight: '500' }}>
                                <i class="fa fa-regular fa-thumbtack me-2" style={{ color: 'rgb(87 23 148)' }}></i>
                                {data?.profession?.domaiOfExpertise?.name}
                            </span>
                        </div>
                        <hr />
                        <div className="details-exp mt-4">
                            <span className="text-truncate me-3 pe-3" style={{ fontWeight: '400', borderRight: '1px solid black', fontSize: '13px' }}>
                                Posted: {formatLocalizedDateDifference(data?.interval?.start)}
                            </span>
                            <span className="text-truncate me-3 pe-3" style={{ fontWeight: '400', borderRight: '1px solid black', fontSize: '13px' }}>
                                DeadLine: {new Date(data?.interval?.end)?.toDateString()}
                            </span>
                            <span className="text-truncate me-3 pe-3" style={{ fontWeight: '400', borderRight: '1px solid black', fontSize: '13px' }}>
                                Openings: {data?.basic?.openings}
                            </span>
                            <span className="text-truncate me-3" style={{ fontWeight: '400', fontSize: '13px' }}>
                                Applicants: 150
                            </span>
                            {
                                !authentication?.token &&
                                <>
                                    <a href='/signin'
                                        className="btn" type="submit" style={{ backgroundColor: '#18042B', color: '#fff', float: 'right' }}

                                    >
                                        Login to Apply
                                    </a>
                                    <a href='/signin'
                                        className="btn me-2" type="submit" style={{ borderColor: '#18042B', color: '#000', float: 'right' }}
                                        onClick={() => dispatch({ type: 'HIDE_MODAL' })}
                                    >
                                        Register to Apply
                                    </a>
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-12 p-5 mb-3" style={{ borderRadius: '20px', boxShadow: '2px 2px 20px 2px #e7e7e7' }}>
                        <h4 className="mb-3">Key Skills</h4>
                        <div className="skills">
                            {
                                data?.profession?.skills && data?.profession?.skills?.map((item) => (
                                    <span style={{ border: '1px solid #aba7a7', padding: '5px 15px', borderRadius: '5px', marginRight: '5px' }} key={item?.id} >{item?.name}</span>
                                ))
                            }


                        </div>

                    </div>
                    <div className="col-lg-12 p-5" style={{ borderRadius: '20px', boxShadow: '2px 2px 20px 2px #e7e7e7' }}>
                        <h4 className="mb-3">Job Description</h4>

                        <p dangerouslySetInnerHTML={{ __html: data?.basic?.description }} />


                        {/* <p className='mb-3'>Dolor justo tempor duo ipsum accusam rebum gubergren erat. Elitr stet dolor vero clita labore gubergren. Kasd sed ipsum elitr clita rebum ut sea diam tempor. Sadipscing nonumy vero labore invidunt dolor sed, eirmod dolore amet aliquyam consetetur lorem, amet elitr clita et sed consetetur dolore accusam. </p>
                        <h4 className="mb-3">Responsibility</h4>
                        <p>Magna et elitr diam sed lorem. Diam diam stet erat no est est. Accusam sed lorem stet voluptua sit sit at stet consetetur, takimata at diam kasd gubergren elitr dolor</p>
                        <ul className="list-unstyled">
                            <li><i className="fa fa-angle-right me-2" style={{ color: 'rgb(87 23 148)' }}></i>Dolor justo tempor duo ipsum accusam</li>
                            <li><i className="fa fa-angle-right me-2" style={{ color: 'rgb(87 23 148)' }}></i>Elitr stet dolor vero clita labore gubergren</li>
                            <li><i className="fa fa-angle-right me-2" style={{ color: 'rgb(87 23 148)' }}></i>Rebum vero dolores dolores elitr</li>

                        </ul>
                        <h4 className="mb-3">Education</h4>
                        <ul className="list-unstyled">
                            <li><i className="fa fa-angle-right me-2" style={{ color: 'rgb(87 23 148)' }}></i><strong>UG:</strong> Any Under Graduate</li>
                            <li><i className="fa fa-angle-right me-2" style={{ color: 'rgb(87 23 148)' }}></i><strong>PG:</strong> Any Post Graduate</li>

                        </ul> */}
                    </div>



                </div>
            </div>
        </div>

    )
}

export default JobDetails