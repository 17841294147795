import React, { useEffect, useState } from 'react'
import "./BenchResources.css"
import BenchresourcesTable from './BenchresourcesTable'
import Accordion from 'react-bootstrap/Accordion';
import Carilogo from "../../../assets/images/cari-logo.png"
import { useLocation } from 'react-router-dom';

const BenchResources = () => {

    const { pathname } = useLocation();

    console.log("Landing runs");
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);



    let count = 0

    const data = [
        {
            name: ["HTML", "CSS", "Javascript"],
            tableData: [
                {
                    resourceName: "Aamir Shabbir",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Md Mudassar A.",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Nigar Fatima",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Amina Khatoon",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
            ]
        },
        {
            name: ["Java"],
            tableData: [
                {
                    resourceName: "Aamir Shabbir",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Saad Ahmed",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
            ]
        },
        {
            name: ["MEAN", "MERN"],
            tableData: [
                {
                    resourceName: "Aamir Shabbir",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Saad Ahmed",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Saad Ahmed",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
                {
                    resourceName: "Saad Ahmed",
                    experience: 4,
                    techStack: [".net", "Python", "Java"],
                    screeningTestMarks: 7.5,
                    technicalAssessment: 7,
                    resume: "https://imgs.search.brave.com/lDFR22j5AOXVrk-Cpy8m3RAl01ulMI-_yvcgU3ag-Vw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9yZXN1/bWVnZW5pdXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy9iYWQt/cmVzdW1lLTEucG5n",
                },
            ]
        }
    ]
    return (
        <div className='BenchResources' >

            <section className="banner">
                <div className="row">
                        <div className="head-st">
                            <h2>Talent Bench</h2>

                            {/* <div class="head-st2">
                                <h2>Cariva Technologies ?</h2>
                                <p>A connecting for IT clients and agencies where clients can find the resources they need based on
                                    technologies and years of experience.</p>
                            </div> */}
                        </div>
                    <div className="col-md-6"></div>
                </div>
            </section>



            <div className="container">
                <Accordion defaultActiveKey="0" className='bg-light'  >
                    {data.map(obj => {
                        return (
                            <Accordion.Item eventKey={count++} key={count} className='accordian'>
                                <Accordion.Header >
                                    <div className="acc_headercont">

                                        <div className='skillMap' ><span>Skill:</span>
                                            {obj.name.map(skill => {
                                                return (
                                                    <span className="accord-skill">{skill}</span>
                                                )
                                            })}
                                        </div>
                                        <div className="count">Count:{obj.tableData.length}</div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body  style={{overflow:'scroll'}} >
                                    <BenchresourcesTable data={obj.tableData} />
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>



        </div>
    )
}

export default BenchResources
