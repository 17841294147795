import React, { useEffect, useState } from "react";
import AgencyCard from "./components/AgencyCard";
import styles from "./Agencies.module.css";
import MyTable from "../../../components/common/childA/table/MyTable";
import {
  fakeDataAgency,
  getLength,
} from "../../../components/common/childA/usersapi/UserApis";
import MyPagination from "../../../components/common/childA/pagination/MyPagination";
import { useDispatch, useSelector } from "react-redux";
import { getAllMemberOfSingleRoleAction } from "../../../Redux/actions/member/getAllMemberOfSingleRoleAction";
import { BServer } from "../../../server/backend";
import stylesTable from "../../../components/common/childA/table/MyTable.module.css";
import { Form } from "react-bootstrap";
import { toggleStatusAgencyAndDevAction } from "../../../Redux/actions/member/toggleStatusAgencyAndDevAction";
import { verifyAgencyAction } from "../../../Redux/actions/member/verifyAgencyAction";
import noprofileFound from "../../../assets/images/no-result-found.png"


const Agencies = () => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const allMember = useSelector(
    (state) => state?.getAllMemberOfSingleRoleReducer
  );
  const inputOnChangeHandle = (e) => {
    setSearchInput(e.target.value);
  };
  console.log("searchInput", searchInput);

  useEffect(() => {
    dispatch(getAllMemberOfSingleRoleAction("agency", searchInput, 1, 10));
  }, [searchInput]);

  const TableHead = [
    "SI No.",
    "Profile Picture",
    "Member Id",
    "Name",
    "Experience",
    "Email",
    "Status",
    "Verification",
  ];
  const TableBody = [
    "memberId",
    "profileId.personalInformation.profilePicture",
    "memberId",
    "name",
    "experience",
    "email",
    "status",
    "verification",
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const totalItems = allMember?.length;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const tableData = allMember?.documents?.slice(firstIndex, lastIndex);
  console.log("tableData", tableData);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return (
    <div>
      <section className="banner">
        <section className={styles.banner}>
          <h3>Agencies</h3>
          <div className={styles.searchbar}>
            <input
              type="text"
              placeholder="Enter name or member id"
              name="searchInput"
              value={searchInput}
              onChange={inputOnChangeHandle}
            />

            <button>Search</button>
          </div>
        </section>
      </section>

      {/* <MyTable tableHead={["SI No.", "Profile Picture", "Member Id", "Name", "Experience", "Email", "Status", "Verification"]} tableBody={["memberId", "profileId.personalInformation.profilePicture", "memberId", "name", "experience", "email", "status", "verification"]} data={tableData} /> */}

      <div className={stylesTable.container}>
        {tableData.length > 0 ? (<table responsive="sm" className={`${stylesTable.custom_table} mt-5 mb-5`}>
          <thead>
            <tr>
              {TableHead.map((val) => {
                return <th key={val}>{val}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((i, idx) => {
              return (
                // <MyTableRow key={user?.memberId} userData={user} tableBody={tableBody} click={props.click} clickFunc={props.clickFunc} />
                <tr
                  className={!i["profileId._id"] && `bg-danger`}
                  style={{ cursor: !i["profileId._id"] && "not-allowed" }}
                >
                  <td>
                    <span>{idx + 1}</span>
                  </td>
                  <td>
                    <span>
                      <img
                        src={`${BServer}/uploads/${i["profileId.personalInformation.profilePicture"]}`}
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          cursor: !i["profileId._id"]
                            ? "not-allowed"
                            : "pointer",
                        }}
                        onClick={() =>
                          i["profileId._id"] &&
                          window.open(
                            `/admin/profile-${i?.userRole}/${encodeURIComponent(
                              JSON.stringify(i?._id)
                            )}`,
                            "_blank"
                          )
                        }
                      />
                    </span>
                  </td>
                  <td>
                    <span>{i?.memberId}</span>
                  </td>
                  <td>
                    <span>{i?.name}</span>
                  </td>
                  <td>
                    <span>
                      {i["profileId.overview.overview_industrialExperience"]}
                    </span>
                  </td>

                  <td>
                    <span>{i["email"]}</span>
                  </td>
                  <td>
                    <span
                      style={{ cursor: "pointer",color:i?.isActive?'green':'red' }}
                      onClick={() =>
                        dispatch(
                          toggleStatusAgencyAndDevAction(
                            i?._id,
                            i?.memberId,
                            i?.userRole,
                            searchInput,
                            currentPage,
                            itemsPerPage
                          )
                        )
                      }
                    >
                      {i?.isActive ? "Active" : "Inactive"}

                      {/* <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={i?.isActive}
                      onClick={() => dispatch(toggleStatusAgencyAndDevAction(i?._id, i?.memberId, i?.userRole, searchInput, currentPage, itemsPerPage))}
                    />
                  </Form> */}
                    </span>
                  </td>

                  <td>
                    <span style={{ cursor: "pointer" }}>
                      <Form>
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          label=""
                          checked={i["profileId.verifiedByAdmin.status"]}
                          onClick={() =>
                            dispatch(
                              verifyAgencyAction(
                                i?._id,
                                i?.memberId,
                                i["profileId.others.assignVerificationTo._id"],
                                searchInput,
                                currentPage,
                                itemsPerPage
                              )
                            )
                          }
                          // disabled={}
                        />
                      </Form>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>) : <div className={styles.noProfilFound}>
              <img className={styles.noprofileFoundImage} src={noprofileFound} alt="" />
              <p >No Agency Found</p>
            </div>}
      </div>

      {tableData.length > 0 && (<div className="a d-flex justify-content-center mt-5 mb-5 ">
        <MyPagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>)}
    </div>
  );
};

export default Agencies;
